import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { PropertyService } from "../../services/property.services";
import { ActivatedRoute, Router } from "@angular/router";
import { SiilaIndexReportComponent } from "../static-pages";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { ImageGalleryComponent } from "../image-gallery/image-gallery.component";
import { BreakpointObserver } from "@angular/cdk/layout";
import { SendNotificationComponent } from "../send-notification/send-notification.component";
import { generate, Observable, of, Subject, zip } from "rxjs";
import { AccountService, NewsService } from "../../../core";
import { NewsSearch } from "../../models/news-search.model";
import { NewsNavigateService } from "../../services/news.navigate.service";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexTheme
} from "ng-apexcharts";
import { apexObj, apexObj2 } from "../../utils/params";
import { DatePipe } from "@angular/common";
import { I18nService } from "../../services";
import { MetaService } from "@ngx-meta/core";
import { FeaturedListingService } from "../../../core/services/featured-listing.service";
import { DeviceRegistrationService } from "../../../core/services/device-registration.service";
import { ProductService } from "../../../core/services/products.service";
import { ProductItem } from "../../../core/models/products.model";
import { color } from "html2canvas/dist/types/css/types/color";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonGenericService } from "../../services/common.service";
import * as e from "express";
import {
  PropertyFeaturesService,
  PropertyOptions
} from "../../services/property-features.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalNumberPipeSpot } from "../../pipes/number-pipe";
import { HttpClient } from "@angular/common/http";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  last,
  switchMap
} from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  stroke: any;
  dataLabels: any;
  fill: ApexFill;
  tooltip: ApexTooltip;
  theme: ApexTheme;
  colors: string[];
  events: any;
  responsive: any;
};

const monthNamesMap = {
  Jan: "Ene",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Abr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Ago",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dic"
};

export type TenantAction = {
  id: number;
  buildingTenant: any;
  actionType: number;
  dateAction: Date;
  tenantNameEdit: string;
  marketSegment: any;
  newTenantArea: any;
  askingRentMXN: number;
  askingRentUSD: number;
  spaceCondition: any;
};

export type BuildingFeatureDetails = {
  featureName: string;
  featureDisplayName: string;
  featureSelectedOptions: string[];
};

export type PropertyFeaturesIds = {
  id: number;
  buildingId: number;
  featureId: number;
  selectedOptions: number[];
  active: boolean;
};

export type UnitMix = {
  floorPlan: string;
  numberOfUnits: number;
  avgArea: number;
  cqAvgMonthlyRent: number;
  monthlyrentsm: number;
  cqAvgMonthlyRentFurnished: number;
  monthlyrentsmf: number;
  detailsName: string;
};

export type MarketSegment = {
  id: number;
  name: string;
};

export type BuildingTenantConfig = {
  building: any;
  id: number;
  quarter: number;
  year: number;
  status: string;
  removedFromStats: boolean;
};

export type MarketTenantConfig = {
  market: any;
  id: number;
  quarter: number;
  year: number;
  status: string;
};

export type BuildingTenantComment = {
  id: number | null;
  configId: number;
  comment: string;
  createdDate: Date;
  user: any;
};

export type BuildingNote = {
  buildingId: number;
  comment: string;
  createdDate: Date;
  user: any;
};

export type AskingRentData = {
  buildingId: number;
  buildingTenants: AskingRentRow[];
  maintenanceFee: number;
  currencyExchangeRate: number;
  askingRentPerArea: number;
  askingRentPerAreaUSD: number;
};

export type AskingRentRow = {
  floorModule: string;
  suiteWarehouse: string;
  tenantName: string;
  askingRentPerArea: number;
  askingRentPerAreaUSD: number;
  conditionId: number;
  moveOut: boolean;
};

export type BuildingFlag = {
  id: number | null;
  configId: number;
  comment: string;
  createdDate: Date;
  user: any;
};

@Component({
  selector: "app-tenants-property",
  templateUrl: "./tenants-property.component.html",
  styleUrls: ["./tenants-property.component.scss"]
})
export class TenantsPropertyComponent implements OnInit, AfterViewInit {
  dialogRef: any;
  id;
  isMobile: boolean = false;
  listingCards;
  property: any;
  photos: any[] = [];
  registry: any[] = [];
  plan: any[] = [];
  documents: any[] = [];
  buildings = [];
  propertiesComparable;
  platformId = environment.platformId;
  owners;
  developers;
  isAdmin = false;
  isResearchAdmin = false;
  isResearcher = false;
  user: any;
  articles: NewsSearch;
  browserLang;
  showDetail = false;
  vacantAreaList: any = [];
  countBomaArea = false;
  marketRentList: any = [];
  occupancies: any = [];
  tenantsLastQuarter: any = [];
  marketSegments: Array<MarketSegment> = [];
  historicOccupancies: any = [];
  countBomaMarket = [];
  industryPercentagesList: any = [];
  public chartOptions: Partial<ChartOptions>;
  public chartOptionsOccupancy: Partial<ChartOptions>;
  public chartOptionsPie: Partial<ChartOptions>;
  tenantAreas: [] = [];
  addTenantSplitSuiteFlag: boolean = false;
  showShortDesciption = true;
  hiddeMoreFeatures;
  pointOfInterest = [];
  vacancySpot: any;
  typeBar = 1;
  marketCardList = [];
  products: ProductItem[];
  currentCode: string;
  listFeatures: any = [];
  listManager: any = [];
  unitsMix: any = [];
  unitMixColVisibility: Map<string, boolean> = new Map();
  featuresOptions: BuildingFeatureDetails[] = [];
  validateMarketRent = [];
  showPhone = false;
  showEmail = false;
  rentRolls = [];
  currentFundsBuilding = [];
  neighborhoodDescription;
  customPropertyFeatures: PropertyOptions[] = [];
  keyFactsMultifamilyOpts: PropertyOptions[] = [];
  areasMeasurementsOpts: PropertyOptions[] = [];
  complexInformation = null;
  countPlannedDate = 0;
  buildingOccupancies: any;
  selectedTenant: any;
  currentAction: any;
  confirmationDialogMode: number = 0;
  editMoveOutDate: Date;
  editMoveOutDateSplitSuite: Date;
  disableCurrencyInput: boolean = false;
  editAskingRentMXN: number;
  editAskingRentUSD: number;
  newTenantArea: number = 0;
  newTenantAreaBoma: number = 0;
  splitSuite: boolean = false;
  moveInSameQuarter: boolean = false;
  config: BuildingTenantConfig;
  marketConfig: MarketTenantConfig;
  comments: BuildingTenantComment[];
  notes: BuildingNote[] = [];
  showNewNoteBox: boolean = false;
  newNote: string = "";

  editTenantWithIndustry: any;
  selectedMarketSegment: MarketSegment;
  selectedMarketSegmentSplitSuite: MarketSegment;
  editTenantName: string = "";
  editIndustryName: string = "";
  autocompleteResults: Array<any> = [];
  autocompleteResultsSplitSuite: Array<any> = [];
  isLoading: boolean = false;
  showAddTenants: boolean = false;
  addTenantMode: boolean = false;
  addTenantModeSplitSuite: boolean = false;
  showAddTenantsSplitSuite: boolean = false;
  editTenantWithIndustrySplitSuite: any;
  editIndustryNameSplitSuite: string = "";
  editTenantNameSplitSuite: string = "";
  newComment: string = "";
  showNewCommentBox: boolean = false;
  rejectionMode: boolean = false;
  private searchSubject = new Subject<string>();
  private searchSubjectSplitSuite = new Subject<string>();
  private mxnInputSubject = new Subject<string>();
  private usdInputSubject = new Subject<string>();
  private mxnInputSubjectAskingRent = new Subject<string>();
  private usdInputSubjectAskingRent = new Subject<string>();
  private suiteAreaInputSubject = new Subject<string>();

  selectedAskingRentData: AskingRentRow;
  selectedSuite: any;
  usdOriginalCurrency: boolean = false;

  additionalSplits: any[] = [];
  maxAdditionalSplits: number = 8;

  originalArea: number = 0;

  @ViewChild("chartModal", { static: false }) chartModal!: ElementRef;

  @ViewChild("confirmationDialog", { static: false })
  confirmationDialog!: TemplateRef<any>;

  @ViewChild("commentsDialog", { static: false })
  commentsDialog!: TemplateRef<any>;

  @ViewChild("notesDialog", { static: false })
  notesDialog!: TemplateRef<any>;

  floorAreas: any[] = [];
  @ViewChild("floorAreaModal", { static: false })
  floorAreaModal!: TemplateRef<any>;

  conditions;
  conditionSelected = null;

  askingRentData: AskingRentData = {
    buildingId: 0,
    buildingTenants: [],
    maintenanceFee: 0,
    currencyExchangeRate: 0,
    askingRentPerArea: 0,
    askingRentPerAreaUSD: 0
  };
  askingRentRows: AskingRentRow[] = [];
  maintenanceFee: number = 0;

  @ViewChild("askingRentModal", { static: false })
  askingRentModal!: TemplateRef<any>;

  @ViewChild("tableContainer") tableContainer!: ElementRef;

  @ViewChild("flagsDialog", { static: false })
  flagsDialog!: TemplateRef<any>;

  flags: BuildingFlag[] = [];
  automaticFlags: any = {};
  showNewFlagBox: boolean = false;
  newFlag: string = "";

  get isBomaMarket() {
    return this.countBomaMarket && this.countBomaMarket.length > 0;
  }

  // Add these properties to your component class
  selectedAreaType: "floor" | "suite" = "floor";
  pauseCalculations: boolean = false;
  suiteAreas: any[] = [];

  // Add these to your component class
  @ViewChild("unsavedChangesModal") unsavedChangesModal: TemplateRef<any>;
  pendingAreaType: "floor" | "suite" = null;

  private initialFloorAreas: any[] = []; // Add this property to store initial state
  showSpinner: boolean = false;

  constructor(
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private siilaTranslationService: SiilaTranslationService,
    private datePipe: DatePipe,
    private i18: I18nService,
    private metaService: MetaService,
    private deviceRegistration: DeviceRegistrationService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.breakpointObserver
      .observe(["(max-width: 450px)"])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.chartOptions = apexObj as ChartOptions;
    this.chartOptionsOccupancy = apexObj2 as ChartOptions;

    this.currentCode = environment.platformId;
  }
  ngAfterViewInit() {
    if (this.isMobile) {
      setTimeout(() => {
        if (this.tableContainer?.nativeElement) {
          this.tableContainer.nativeElement.scrollLeft = 65;
        }
      }, 4000);
    }
  }

  async ngOnInit() {
    if (this.validateSesion()) {
      this.conditions = await this.propertyService.getSpaceConditionByType();
      this.route.params.subscribe(params => {
        this.id = params.id;
        this.propertyService.getPropertyId(this.id).then(resp => {
          this.property = resp;
          this.metaService.setTitle(
            this.i18.getTranslation(this.property.title)
          );

          zip(
            this.propertyService.getLastQuarterTenantsInfo(this.id),
            this.propertyService.getMarketSegments()
          ).subscribe(async resp => {
            this.showDetail = true;

            this.tenantsLastQuarter = (resp[0] as any).buildingTenants;
            this.config = (resp[0] as any).config;
            this.comments = (resp[0] as any).comments;
            this.notes = (resp[0] as any).notes || [];
            this.flags = (resp[0] as any).flags || [];
            this.automaticFlags = (resp[0] as any).automaticFlags || {};
            this.marketConfig = (resp[0] as any).marketConfig;

            // Add some dummy data for flags
            /* if (!this.flags || this.flags.length === 0) {
              this.flags = [
                {
                  id: 1,
                  configId: this.config.id,
                  comment: "Important: Review tenant data",
                  createdDate: new Date(),
                  user: this.user
                },
                {
                  id: 2,
                  configId: this.config.id,
                  comment: "Pending: Area verification needed",
                  createdDate: new Date(),
                  user: this.user
                }
              ];
            } */

            this.updateCheckboxValues(this.tenantsLastQuarter);
            this.marketSegments = resp[1] as MarketSegment[];
            this.marketSegments.push({
              id: 0,
              name: '{"es":"Unknown", "en":"Unknown", "pt-br":"Unknown"}'
            });
            // remove market segment with name "XXX"
            this.marketSegments = this.marketSegments.filter(
              segment => !segment.name.includes("Other")
            );
            this.marketSegments = this.marketSegments.sort((a, b) =>
              this.siilaTranslationService
                .getTranslation(a.name)
                .localeCompare(
                  this.siilaTranslationService.getTranslation(b.name)
                )
            );
          });
        });
      });
    } else {
      this.router.navigateByUrl("/");
    }

    this.mxnInputSubjectAskingRent.pipe(debounceTime(300)).subscribe(query => {
      if (query.length >= 2 && this.isMX()) {
        let askingRentPerArea = parseFloat(query);
        this.selectedAskingRentData.askingRentPerArea = askingRentPerArea;
        this.selectedAskingRentData.askingRentPerAreaUSD =
          Math.round(
            (askingRentPerArea / this.askingRentData.currencyExchangeRate) * 100
          ) / 100;
      }
    });

    this.usdInputSubjectAskingRent.pipe(debounceTime(300)).subscribe(query => {
      if (query.length >= 2 && this.isMX()) {
        let askingRentPerAreaUSD = parseFloat(query);
        this.selectedAskingRentData.askingRentPerAreaUSD = askingRentPerAreaUSD;
        this.selectedAskingRentData.askingRentPerArea =
          Math.round(
            askingRentPerAreaUSD *
              this.askingRentData.currencyExchangeRate *
              100
          ) / 100;
      }
    });

    this.suiteAreaInputSubject.pipe(debounceTime(900)).subscribe(query => {
      if (query.length >= 1) {
        this.selectedSuite.newArea = parseFloat(query);
        if (!this.pauseCalculations) {
          this.calculateSuiteAreas();
        }
      }
    });

    this.mxnInputSubject.pipe(debounceTime(300)).subscribe(query => {
      if (query.length >= 2 && this.editMoveOutDate && this.isMX()) {
        this.editAskingRentMXN = parseFloat(query);
        this.propertyService
          .getExchangeRate(this.editMoveOutDate)
          .then((resp: any) => {
            if (resp.value) {
              this.editAskingRentUSD =
                Math.round((this.editAskingRentMXN / resp.value) * 100) / 100;
            }
          });
      }
    });

    this.usdInputSubject.pipe(debounceTime(300)).subscribe(query => {
      if (query.length >= 2 && this.editMoveOutDate && this.isMX()) {
        this.editAskingRentUSD = parseFloat(query);
        this.propertyService
          .getExchangeRate(this.editMoveOutDate)
          .then((resp: any) => {
            if (resp.value) {
              this.editAskingRentMXN =
                Math.round(this.editAskingRentUSD * resp.value * 100) / 100;
            }
          });
      }
    });

    this.searchSubjectSplitSuite
      .pipe(
        debounceTime(300), // Wait 300 ms after typing stops
        distinctUntilChanged(), // Ignore duplicate queries
        switchMap(query => {
          this.editTenantWithIndustrySplitSuite = null;
          this.showAddTenantsSplitSuite = false;
          if (query.length > 3) {
            this.isLoading = true;
            return this.propertyService
              .fetchTenantsAutocompleteResults(query)
              .pipe(
                catchError(error => {
                  console.error("Error fetching autocomplete results:", error);
                  this.isLoading = false; // Stop loading if error occurs
                  return of([]); // Return an empty array in case of error
                })
              );
          } else {
            this.isLoading = false;
            return of([]); // If input is too short, return an empty array
          }
        })
      )
      .subscribe(results => {
        this.isLoading = false; // Stop loading icon once results are received
        this.autocompleteResultsSplitSuite = results; // Update dropdown with results
        // remove values matching "desconhecido"
        this.autocompleteResultsSplitSuite = this.autocompleteResultsSplitSuite.filter(
          result => !this.isUnknownTenant(result.tenantName)
        );
        if (this.editTenantNameSplitSuite.length < 4) {
          return;
        }
        if (
          "unknown".includes(this.editTenantNameSplitSuite.toLowerCase()) ||
          this.isUnknownTenant(this.editTenantNameSplitSuite)
        ) {
          this.autocompleteResultsSplitSuite.push({
            tenantName: "Unknown",
            industryName: this.editIndustryNameSplitSuite
          });
        }

        if ("desconhecido".includes(this.editTenantName.toLowerCase())) {
          this.autocompleteResults.push({
            tenantName: "Desconhecido",
            industryName: this.editIndustryName
          });
        }

        if (
          this.editTenantNameSplitSuite.toLowerCase() != "unknown" &&
          !this.isUnknownTenant(this.editTenantNameSplitSuite)
        ) {
          if (
            !this.autocompleteResultsSplitSuite.some(
              result =>
                result.tenantName.toLowerCase() ===
                this.editTenantNameSplitSuite.toLowerCase()
            )
          ) {
            this.autocompleteResultsSplitSuite.push({
              tenantName:
                'Add "' +
                this.editTenantNameSplitSuite.trim() +
                '" as a new tenant',
              industryName: this.editIndustryNameSplitSuite
            });
          }
        }

        if (
          this.autocompleteResultsSplitSuite.length == 0 &&
          this.editTenantNameSplitSuite.length > 3
        ) {
          this.showAddTenantsSplitSuite = true;
        }
      });

    this.searchSubject
      .pipe(
        debounceTime(300), // Wait 300 ms after typing stops
        distinctUntilChanged(), // Ignore duplicate queries
        switchMap(query => {
          this.editTenantWithIndustry = null;
          this.showAddTenants = false;
          if (query.length > 3) {
            this.isLoading = true;
            return this.propertyService
              .fetchTenantsAutocompleteResults(query)
              .pipe(
                catchError(error => {
                  console.error("Error fetching autocomplete results:", error);
                  this.isLoading = false; // Stop loading if error occurs
                  return of([]); // Return an empty array in case of error
                })
              );
          } else {
            this.isLoading = false;
            return of([]); // If input is too short, return an empty array
          }
        })
      )
      .subscribe(results => {
        this.isLoading = false; // Stop loading icon once results are received
        this.autocompleteResults = results; // Update dropdown with results
        if (this.editTenantName.length < 4) {
          return;
        }
        // remove values matching "desconhecido"
        this.autocompleteResults = this.autocompleteResults.filter(
          result => !this.isUnknownTenant(result.tenantName)
        );
        if (
          "unknown".includes(this.editTenantName.toLowerCase()) ||
          this.isUnknownTenant(this.editTenantName)
        ) {
          this.autocompleteResults.push({
            tenantName: "Unknown",
            industryName: this.editIndustryName
          });
        }

        if ("desconhecido".includes(this.editTenantName.toLowerCase())) {
          this.autocompleteResults.push({
            tenantName: "Desconhecido",
            industryName: this.editIndustryName
          });
        }

        if (
          this.editTenantName.toLowerCase() != "unknown" &&
          !this.isUnknownTenant(this.editTenantName)
        ) {
          if (
            !this.autocompleteResults.some(
              result =>
                result.tenantName.toLowerCase() ===
                this.editTenantName.toLowerCase()
            )
          ) {
            this.autocompleteResults.push({
              tenantName:
                'Add "' + this.editTenantName.trim() + '" as a new tenant',
              industryName: this.editIndustryName
            });
          }
        }

        if (
          this.autocompleteResults.length == 0 &&
          this.editTenantName.length > 3
        ) {
          this.showAddTenants = true;
        }
      });
  }

  onDateChange(newDate: string): void {
    if (newDate && this.isMX()) {
      this.propertyService
        .getExchangeRate(this.editMoveOutDate)
        .then((resp: any) => {
          if (resp.value) {
            if (this.editAskingRentUSD) {
              this.editAskingRentMXN =
                Math.round(this.editAskingRentUSD * resp.value * 100) / 100;
            }
            if (this.editAskingRentMXN) {
              this.editAskingRentUSD =
                Math.round((this.editAskingRentMXN / resp.value) * 100) / 100;
            }
          }
        });
    }
  }

  onBackToVacantSplitSuite() {
    this.addTenantModeSplitSuite = false;
    this.showAddTenantsSplitSuite = false;
    this.editTenantWithIndustrySplitSuite = null;
    this.editIndustryNameSplitSuite = "";
    this.editTenantNameSplitSuite = "";
    this.autocompleteResultsSplitSuite = [];
    this.addTenantSplitSuiteFlag = false;
    this.selectedMarketSegmentSplitSuite = null;
  }

  disableManualEntry(event: KeyboardEvent): void {
    event.preventDefault();
  }

  formatDates(date: Date | string): string {
    if (!date) return "";

    var formatDate =
      this.i18.getCurrentLanguage() === "pt-br" ? "dd/MM/yyyy" : "MM/dd/yyyy";

    return this.datePipe.transform(date, formatDate) || "";
  }

  onAddTenant() {
    this.addTenantMode = true;
  }

  onAddTenantSplitSuite() {
    this.addTenantModeSplitSuite = true;
  }

  onBackToSearchTenant() {
    this.addTenantMode = false;
    this.showAddTenants = false;
    this.editTenantWithIndustry = null;
    this.editIndustryName = "";
    this.editTenantName = "";
  }

  onBackToSearchTenantSplitSuite() {
    this.addTenantModeSplitSuite = false;
    this.showAddTenantsSplitSuite = false;
    this.editTenantWithIndustrySplitSuite = null;
    this.editIndustryNameSplitSuite = "";
    this.editTenantNameSplitSuite = "";
  }

  translateLabel(name) {
    return this.i18.getTranslation(name);
  }

  setQuarter(date) {
    let [year, month, day] = date.split("-").map(Number);
    let input = new Date(year, month - 1, day);
    let quarter = Math.floor((input.getMonth() + 3) / 3);
    return `${quarter} ${
      this.browserLang === "pt" || this.browserLang === "es" ? "T" : "Q"
    } ${this.datePipe.transform(input, "yyyy")}`;
  }

  async validateSesion() {
    if (localStorage.getItem("token")) {
      return this.setUser();
    } else {
      let isValidToken = this.deviceRegistration.validateCookie();

      if (isValidToken) {
        let account = await this.accountService.getAccount();
        localStorage.setItem("user", JSON.stringify(account));
        return this.setUser();
      }
    }

    return false;
  }

  setUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.user = user;
    this.isAdmin = user?.roles?.includes("ROLE_ADMIN");
    this.isResearcher = user?.roles?.includes("ROLE_RESEARCH");
    this.isResearchAdmin =
      user?.roles?.includes("ROLE_COUNTRY_MANAGER") ||
      user?.roles?.includes("ROLE_RESEARCH_LEAD");

    if (
      !user?.roles?.includes("ROLE_COUNTRY_MANAGER") &&
      !user?.roles?.includes("ROLE_RESEARCH_LEAD") &&
      !user?.roles?.includes("ROLE_RESEARCH")
    ) {
      this.snackBar.open("You don't have access to Research section", "Close", {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: "center",
        verticalPosition: "top",
        panelClass: ["custom-snackbar"]
      });
      this.router.navigateByUrl("/");
      return false;
    }
    return true;
  }

  isMX() {
    return this.platformId && this.platformId == "mx";
  }

  openChartPopup(content: any, mode: string) {
    if (mode == "edit") {
      setTimeout(() => {
        this.selectedTenant.checkboxEdit = true;
      }, 10);
    }

    if (mode == "moveOut") {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveOut = true;
      }, 10);
    }

    if (mode == "moveIn") {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveIn = true;
      }, 10);
    }

    this.modalService.open(content, {
      centered: true,
      backdrop: false,
      keyboard: false,
      windowClass: "custom-modal-class-tenants",
      backdropClass: "custom-modal-class-tenants"
    });
  }

  isFutureDate(date) {
    if (date) {
      const currentDate = new Date();
      const parsedDate = new Date(date + "T00:00:00");
      if (parsedDate > currentDate) {
        this.countPlannedDate++;
      }
      return parsedDate > currentDate ? this.formatDate(date) : "";
    }
    return "";
  }

  isPastDate(date) {
    if (date) {
      const currentDate = new Date();
      const parsedDate = new Date(date + "T00:00:00");
      return parsedDate > currentDate ? "" : this.formatDate(date);
    } else {
      return "";
    }
  }

  formatDate(value) {
    if (value != "") {
      const date = new Date(value + "T00:00:00");
      const month = date.toLocaleString("en", { month: "short" });
      const year = date.getFullYear();

      return this.browserLang === "es"
        ? `${monthNamesMap[month]} ${year}`
        : `${month} ${year}`;
    }
    return value;
  }

  translateStatus(status: string): string {
    if (status === "Active") {
      return "Activo";
    } else if (status === "Inactive") {
      return "Inactivo";
    }
  }

  onInputChange(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.searchSubject.next(input);
  }

  onInputChangeSplitSuite(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.searchSubjectSplitSuite.next(input);
  }

  onInputChangeMXN(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.usdOriginalCurrency = false; // MXN was entered directly
    this.mxnInputSubject.next(input);
  }

  onInputChangeUSD(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.usdOriginalCurrency = true; // USD was entered directly
    this.usdInputSubject.next(input);
  }

  onInputChangeAskingRentPerArea(event: Event, row: any): void {
    const input = (event.target as HTMLInputElement).value;
    this.selectedAskingRentData = row;
    this.mxnInputSubjectAskingRent.next(input);
  }

  onInputChangeAskingRentPerAreaUSD(event: Event, row: any): void {
    const input = (event.target as HTMLInputElement).value;
    this.selectedAskingRentData = row;
    this.usdInputSubjectAskingRent.next(input);
  }

  onInputChangeSuiteArea(event: Event, suite: any): void {
    const input = (event.target as HTMLInputElement).value;
    suite.newArea = parseFloat(input);
    this.selectedSuite = suite;
    this.suiteAreaInputSubject.next(input);
  }

  onSelectOption(option: any): void {
    if (
      option.tenantName.toLowerCase().includes("unknown") ||
      this.isUnknownTenant(option.tenantName)
    ) {
      this.editTenantName = option.tenantName;
      this.editTenantWithIndustry = option;
      this.editIndustryName = option.industryName;

      this.addTenantMode = true;
      this.showAddTenants = false;
      this.autocompleteResults = [];
    } else if (option.tenantName.includes("new tenant")) {
      this.editIndustryName = null;
      this.addTenantMode = true;
      this.showAddTenants = false;
      this.editTenantWithIndustry = option;
      this.autocompleteResults = [];
    } else {
      this.editTenantWithIndustry = option;
      this.editTenantName = option.tenantName;
      this.editIndustryName = option.industryName;
      this.autocompleteResults = []; // Clear the autocomplete dropdown
    }

    this.selectedMarketSegment = this.marketSegments.find(
      item => item.id == this.editTenantWithIndustry.industryId
    );
  }

  onSelectOptionSplitSuite(option: any): void {
    if (
      option.tenantName.toLowerCase().includes("unknown") ||
      this.isUnknownTenant(option.tenantName)
    ) {
      this.editTenantNameSplitSuite = option.tenantName;
      this.editTenantWithIndustrySplitSuite = option;
      this.editIndustryNameSplitSuite = option.industryName;

      this.addTenantModeSplitSuite = true;
      this.showAddTenantsSplitSuite = false;
      this.autocompleteResultsSplitSuite = [];
    } else if (option.tenantName.includes("new tenant")) {
      this.editIndustryNameSplitSuite = null;
      this.addTenantModeSplitSuite = true;
      this.showAddTenantsSplitSuite = false;
      this.editTenantWithIndustrySplitSuite = option;
      this.autocompleteResultsSplitSuite = [];
    } else {
      this.editTenantWithIndustrySplitSuite = option;
      this.editTenantNameSplitSuite = option.tenantName;
      this.editIndustryNameSplitSuite = option.industryName;
      this.autocompleteResultsSplitSuite = []; // Clear the autocomplete dropdown
    }

    this.selectedMarketSegmentSplitSuite = this.marketSegments.find(
      item => item.id == this.editTenantWithIndustrySplitSuite.industryId
    );
  }

  resetModalValues() {
    this.editTenantWithIndustry = null;
    this.editIndustryName = "";
    this.editTenantName = "";

    this.editTenantWithIndustrySplitSuite = null;
    this.editIndustryNameSplitSuite = "";
    this.editTenantNameSplitSuite = "";

    this.editMoveOutDate = null;
    this.editMoveOutDateSplitSuite = null;
    this.editAskingRentMXN = null;
    this.editAskingRentUSD = null;

    this.autocompleteResults = [];
    this.autocompleteResultsSplitSuite = [];
    this.isLoading = false;
    this.newTenantArea = null;
    this.newTenantAreaBoma = null;
    this.splitSuite = false;
    this.moveInSameQuarter = false;

    this.selectedMarketSegment = null;
    this.selectedMarketSegmentSplitSuite = null;
    this.showAddTenants = false;
    this.showAddTenantsSplitSuite = false;
    this.addTenantMode = false;
    this.addTenantModeSplitSuite = false;
    this.addTenantSplitSuiteFlag = false;
  }

  discardChanges() {
    if (this.currentAction == "edit") {
      this.selectedTenant.checkboxEdit = false;
      this.selectedTenant.actionEdit = null;
      this.resetModalValues();
      this.modalService.dismissAll();
    }

    if (this.currentAction == "moveOut") {
      this.selectedTenant.checkboxMoveOut = false;
      this.selectedTenant.actionMoveOut = null;
      this.selectedTenant.checkboxMoveIn = false;
      this.selectedTenant.actionMoveIn = null;
      this.resetModalValues();
      this.modalService.dismissAll();
    }

    if (this.currentAction == "moveIn") {
      this.selectedTenant.checkboxMoveIn = false;
      this.selectedTenant.actionMoveIn = null;
      this.resetModalValues();
      this.modalService.dismissAll();
    }

    this.discardDataSingleTenant(this.selectedTenant);
  }

  onCheckboxClick(event: Event, tenantRow: any, action): void {
    this.selectedTenant = tenantRow;
    this.currentAction = action;
    this.resetModalValues();
    this.selectedMarketSegment = null;
    this.originalArea = this.selectedTenant.newArea ?? this.selectedTenant.area;
    this.restoreAdditionalSplits();
    if (action == "edit") {
      if (this.selectedTenant.actionEdit) {
        this.editTenantName = this.selectedTenant.actionEdit.tenantNameEdit;
        if (this.selectedTenant.actionEdit.marketSegment) {
          this.editIndustryName = this.selectedTenant.actionEdit.marketSegment.name;
          this.selectedMarketSegment = this.marketSegments.find(
            item => item.id == this.selectedTenant.actionEdit.marketSegment.id
          );
        }
        if (this.selectedTenant.actionEdit.corporation) {
          this.editTenantName = this.selectedTenant.actionEdit.corporation.name;
          this.editIndustryName = this.selectedTenant.actionEdit.corporation.industry.name;
          this.selectedMarketSegment = this.marketSegments.find(
            item =>
              item.id == this.selectedTenant.actionEdit.corporation.industry.id
          );
        }
        let tenantName = this.selectedTenant.actionEdit.corporation
          ? this.selectedTenant.actionEdit.corporation.name
          : this.selectedTenant.actionEdit.tenantNameEdit;
        let industryName = this.selectedTenant.actionEdit.corporation
          ? this.selectedTenant.actionEdit.corporation.industry.name
          : this.editIndustryName;
        let industryId = this.selectedTenant.actionEdit.corporation
          ? this.selectedTenant.actionEdit.corporation.industry.id
          : this.selectedMarketSegment
          ? this.selectedMarketSegment.id
          : null;
        this.editTenantWithIndustry = {
          corporation: this.selectedTenant.actionEdit.corporation,
          corporationId: this.selectedTenant.actionEdit.corporation
            ? this.selectedTenant.actionEdit.corporation.id
            : null,
          tenantName: tenantName,
          industryName: industryName,
          industryId: industryId
        };
      } else {
        //this.resetModalValues();
      }
    }

    if (action == "moveOut") {
      this.editTenantName = this.selectedTenant.tenantName;
      if (this.selectedTenant.actionMoveOut) {
        this.moveInSameQuarter = this.selectedTenant.actionMoveOut.moveInSameQuarter;
        this.editMoveOutDate = this.selectedTenant.actionMoveOut.dateAction;
        if (this.isMX()) {
          this.editAskingRentMXN = this.selectedTenant.actionMoveOut.askingRentPerAreaMXN;
          this.editAskingRentUSD = this.selectedTenant.actionMoveOut.askingRentPerAreaUSD;
        } else {
          this.editAskingRentMXN = this.selectedTenant.actionMoveOut.askingRentPerArea;
        }

        this.usdOriginalCurrency =
          this.selectedTenant.actionMoveOut.usdOriginalCurrency || false;
        if (this.moveInSameQuarter) {
          this.editAskingRentMXN = null;
          this.editAskingRentUSD = null;
          this.disableCurrencyInput = true;
        } else {
          this.disableCurrencyInput = false;
        }
      }
    }

    if (action == "moveIn") {
      if (this.selectedTenant.actionMoveIn) {
        this.editTenantName = this.selectedTenant.actionMoveIn.tenantNameEdit;
        this.editTenantNameSplitSuite = this.selectedTenant.actionMoveIn.tenantNameEditSplitSuite;
        this.editMoveOutDate = this.selectedTenant.actionMoveIn.dateAction;
        this.editMoveOutDateSplitSuite = this.selectedTenant.actionMoveIn.dateActionSplitSuite;
        if (this.selectedTenant.actionMoveIn.marketSegment) {
          this.editIndustryName = this.selectedTenant.actionMoveIn.marketSegment.name;
          this.selectedMarketSegment = this.marketSegments.find(
            item => item.id == this.selectedTenant.actionMoveIn.marketSegment.id
          );
        }

        if (this.selectedTenant.actionMoveIn.marketSegmentSplitSuite) {
          this.editIndustryNameSplitSuite = this.selectedTenant.actionMoveIn.marketSegmentSplitSuite.name;
          this.selectedMarketSegmentSplitSuite = this.marketSegments.find(
            item =>
              item.id ==
              this.selectedTenant.actionMoveIn.marketSegmentSplitSuite.id
          );
        }

        if (
          this.isUnknownTenant(this.editTenantName) &&
          !this.selectedTenant.actionMoveIn.marketSegment
        ) {
          this.selectedMarketSegment = this.marketSegments.find(
            item => item.id == 0
          );
          this.editIndustryName = this.selectedMarketSegment.name;
        }

        if (
          this.isUnknownTenant(this.editTenantNameSplitSuite) &&
          !this.selectedTenant.actionMoveIn.marketSegmentSplitSuite
        ) {
          this.selectedMarketSegmentSplitSuite = this.marketSegments.find(
            item => item.id == 0
          );
          this.editIndustryNameSplitSuite = this.selectedMarketSegmentSplitSuite.name;
        }

        if (this.selectedTenant.actionMoveIn.corporation) {
          this.editTenantName = this.selectedTenant.actionMoveIn.corporation.name;
          this.editIndustryName = this.selectedTenant.actionMoveIn.corporation.industry.name;
          this.selectedMarketSegment = this.marketSegments.find(
            item =>
              item.id ==
              this.selectedTenant.actionMoveIn.corporation.industry.id
          );
        }

        let tenantName = this.selectedTenant.actionMoveIn.corporation
          ? this.selectedTenant.actionMoveIn.corporation.name
          : this.selectedTenant.actionMoveIn.tenantNameEdit;
        let industryName = this.selectedTenant.actionMoveIn.corporation
          ? this.selectedTenant.actionMoveIn.corporation.industry.name
          : this.editIndustryName;
        let industryId = this.selectedTenant.actionMoveIn.corporation
          ? this.selectedTenant.actionMoveIn.corporation.industry.id
          : this.selectedMarketSegment
          ? this.selectedMarketSegment.id
          : null;

        this.editTenantWithIndustry = {
          corporation: this.selectedTenant.actionMoveIn.corporation,
          corporationId: this.selectedTenant.actionMoveIn.corporation
            ? this.selectedTenant.actionMoveIn.corporation.id
            : null,
          tenantName: tenantName,
          industryName: industryName,
          industryId: industryId
        };

        this.editTenantWithIndustrySplitSuite = {
          tenantName: this.selectedTenant.actionMoveIn.tenantNameEditSplitSuite,
          industryName: this.editIndustryNameSplitSuite,
          industryId: this.selectedMarketSegmentSplitSuite
            ? this.selectedMarketSegmentSplitSuite.id
            : null
        };

        this.splitSuite = this.selectedTenant.actionMoveIn.splitSuite;
        this.newTenantArea = this.selectedTenant.actionMoveIn.newTenantArea
          ? this.selectedTenant.actionMoveIn.newTenantArea
          : 0;
        this.newTenantAreaBoma = this.selectedTenant.actionMoveIn
          .newTenantAreaBoma
          ? this.selectedTenant.actionMoveIn.newTenantAreaBoma
          : 0;

        if (this.selectedTenant.actionMoveIn.tenantNameEditSplitSuite) {
          this.addTenantModeSplitSuite = true;
          this.showAddTenantsSplitSuite = false;
          this.autocompleteResultsSplitSuite = [];
          this.addTenantSplitSuiteFlag = true;
        }
      }
    }

    this.openChartPopup(this.chartModal, action);
  }

  restoreAdditionalSplits() {
    if (
      this.currentAction == "moveIn" &&
      this.selectedTenant.actionMoveIn &&
      this.selectedTenant.actionMoveIn.splitSuites
    ) {
      this.additionalSplits = [];
      this.selectedTenant.actionMoveIn.splitSuites.forEach(split => {
        let segment = split.marketSegment
          ? this.marketSegments.find(item => item.id == split.marketSegment.id)
          : null;
        let tenantName = split.tenantName;
        if (split.corporation) {
          tenantName = split.corporation.name;
          segment = this.marketSegments.find(
            item => item.id == split.corporation.industry.id
          );
        }
        this.additionalSplits.push({
          area: split.tenantArea,
          tenantName: split.tenantName,
          floorModule: split.floorModule,
          suiteWarehouse: split.suiteWarehouse,
          marketSegment: segment,
          dateAction: split.dateAction,
          corporation: split.corporation,
          addTenantFlag:
            split.tenantName != "Vacant" && split.tenantName != ""
              ? true
              : false,
          autocompleteResults: []
        });
      });
    } else {
      this.additionalSplits = [];
    }
  }

  downloadStatsCsvWithChanges() {
    this.showSpinner = true;
    this.propertyService
      .downloadStatsCsvWithChanges(this.property.id)
      .then(response => {
        const blob = new Blob([response], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = `property_stats_property_id_${this.property.id}.csv`;
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(error => {
        console.error("Error downloading CSV:", error);
        this.snackBar.open("Error downloading CSV file", "Close", {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["custom-snackbar"]
        });
      })
      .finally(() => {
        this.showSpinner = false;
      });
  }

  addTenantSplitSuite() {
    this.addTenantSplitSuiteFlag = true;
  }

  saveChanges() {
    if (this.currentAction == "edit" || this.currentAction == "moveIn") {
      let actionObj =
        this.currentAction == "edit"
          ? this.selectedTenant.actionEdit
          : this.selectedTenant.actionMoveIn;

      if (
        !this.addTenantModeSplitSuite &&
        !this.editTenantWithIndustrySplitSuite &&
        actionObj &&
        actionObj.marketSegmentSplitSuite
      ) {
        this.editTenantWithIndustrySplitSuite = {};
        this.editTenantWithIndustrySplitSuite.industryName =
          actionObj.marketSegmentSplitSuite.name;
        this.editTenantWithIndustrySplitSuite.industryId =
          actionObj.marketSegmentSplitSuite.id;
      } else {
        if (
          (!this.editTenantWithIndustrySplitSuite ||
            !this.editTenantWithIndustrySplitSuite.industryId) &&
          this.selectedMarketSegmentSplitSuite
        ) {
          this.editTenantWithIndustrySplitSuite = {};
          this.editTenantWithIndustrySplitSuite.industryName = this.selectedMarketSegmentSplitSuite.name;
          this.editTenantWithIndustrySplitSuite.industryId = this.selectedMarketSegmentSplitSuite.id;
        }
      }

      if (
        !this.addTenantMode &&
        !this.editTenantWithIndustry &&
        actionObj &&
        actionObj.marketSegment
      ) {
        this.editTenantWithIndustry = {};
        this.editTenantWithIndustry.industryName = actionObj.marketSegment.name;
        this.editTenantWithIndustry.industryId = actionObj.marketSegment.id;
      } else {
        if (
          (!this.editTenantWithIndustry ||
            !this.editTenantWithIndustry.industryId) &&
          this.selectedMarketSegment
        ) {
          this.editTenantWithIndustry = {};
          this.editTenantWithIndustry.industryName = this.selectedMarketSegment.name;
          this.editTenantWithIndustry.industryId = this.selectedMarketSegment.id;
        }
      }

      if (
        this.isUnknownTenant(this.editTenantName) &&
        this.selectedMarketSegment
      ) {
        this.editTenantWithIndustry = {};
        this.editTenantWithIndustry.industryName = this.selectedMarketSegment.name;
        this.editTenantWithIndustry.industryId = this.selectedMarketSegment.id;
      }
    }

    let corporation = null;
    if (
      this.editTenantWithIndustry &&
      this.editTenantWithIndustry.corporationId
    ) {
      corporation = {
        id: this.editTenantWithIndustry.corporationId
      };
    }

    if (this.currentAction == "edit") {
      this.selectedTenant.actionEdit = {
        actionType: 0,
        tenantNameEdit: this.editTenantName,
        marketSegment: {
          name: this.editTenantWithIndustry.industryName,
          id: this.editTenantWithIndustry.industryId
        },
        buildingTenant: {
          id: this.selectedTenant.id
        },
        corporation: corporation,
        dateAction: new Date().toISOString()
      };
    }

    if (this.currentAction == "moveOut") {
      this.selectedTenant.actionMoveOut = {
        actionType: 1,
        dateAction: this.editMoveOutDate,
        askingRentPerAreaMXN: this.editAskingRentMXN,
        askingRentPerArea: this.editAskingRentMXN,
        askingRentPerAreaUSD: this.editAskingRentUSD,
        buildingTenant: {
          id: this.selectedTenant.id
        },
        usdOriginalCurrency: this.usdOriginalCurrency,
        spaceCondition: this.conditionSelected
      };

      this.selectedTenant.actionMoveOut.moveInSameQuarter = this.moveInSameQuarter;
      this.conditionSelected = null;
    }

    if (this.currentAction == "moveIn") {
      this.selectedTenant.actionMoveIn = {
        actionType: 2,
        tenantNameEdit: this.editTenantName,
        tenantNameEditSplitSuite: this.editTenantNameSplitSuite,
        id: this.selectedTenant.actionMoveIn
          ? this.selectedTenant.actionMoveIn.id
          : null,
        marketSegment: {
          name: this.editTenantWithIndustry.industryName,
          id: this.editTenantWithIndustry.industryId
        },
        corporation: corporation,
        dateAction: this.editMoveOutDate,
        dateActionSplitSuite: this.editMoveOutDateSplitSuite,
        buildingTenant: {
          id: this.selectedTenant.id
        }
      };

      if (this.additionalSplits.length > 0) {
        this.selectedTenant.actionMoveIn.splitSuites = [];
        this.additionalSplits.forEach(split => {
          if (split.area > 0) {
            this.selectedTenant.actionMoveIn.splitSuites.push({
              tenantArea: split.area,
              tenantName: split.tenantName,
              floorModule: split.floorModule,
              suiteWarehouse: split.suiteWarehouse,
              marketSegment: split.marketSegment,
              corporation: split.corporation,
              dateAction: split.dateAction
                ? split.dateAction
                : new Date().toISOString()
            });
          }
        });
      } else {
        this.selectedTenant.actionMoveIn.splitSuites = null;
      }

      if (this.splitSuite) {
        if (
          this.editTenantWithIndustrySplitSuite &&
          this.editTenantWithIndustrySplitSuite.industryId > 0
        ) {
          this.selectedTenant.actionMoveIn.marketSegmentSplitSuite = {
            name: this.editTenantWithIndustrySplitSuite.industryName,
            id: this.editTenantWithIndustrySplitSuite.industryId
          };
        } else {
          this.selectedTenant.actionMoveIn.marketSegmentSplitSuite = null;
        }
      }

      if (
        this.editTenantWithIndustry &&
        this.editTenantWithIndustry.industryId == 0
      ) {
        this.selectedTenant.actionMoveIn.marketSegment = null;
      }
      if (this.splitSuite) {
        this.selectedTenant.actionMoveIn.newTenantArea = this.newTenantArea;
        this.selectedTenant.actionMoveIn.newTenantAreaBoma = this.newTenantAreaBoma;
        this.selectedTenant.actionMoveIn.splitSuite = true;
      } else {
        this.selectedTenant.actionMoveIn.newTenantArea = null;
        this.selectedTenant.actionMoveIn.newTenantAreaBoma = null;
        this.selectedTenant.actionMoveIn.splitSuite = false;
      }
    }

    this.saveDataSingleTenant(this.selectedTenant);
    this.modalService.dismissAll();
  }

  onSplitSuiteChange(event: any): void {
    this.splitSuite = event.target.checked;
    if (!this.splitSuite) {
      this.editTenantNameSplitSuite = "";
      this.editTenantWithIndustrySplitSuite = null;
      this.selectedMarketSegmentSplitSuite = null;
      this.addTenantModeSplitSuite = false;
      this.showAddTenantsSplitSuite = false;
      this.autocompleteResultsSplitSuite = [];
    }
  }

  updateCheckboxValues(tenantsLastQuarter) {
    tenantsLastQuarter.forEach(tenant => {
      if (tenant.actionEdit) {
        tenant.checkboxEdit = true;
      }
      if (tenant.actionMoveOut) {
        tenant.checkboxMoveOut = true;
      }
      if (tenant.actionMoveIn) {
        tenant.checkboxMoveIn = true;
      }
    });
  }

  closeModal() {
    if (!this.selectedTenant.actionEdit) {
      setTimeout(() => {
        this.selectedTenant.checkboxEdit = false;
      }, 10);
    }
    if (!this.selectedTenant.actionMoveOut) {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveOut = false;
      }, 10);
    }
    if (!this.selectedTenant.actionMoveIn) {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveIn = false;
      }, 10);
    }

    this.conditionSelected = null;
    this.modalService.dismissAll();
  }

  validateSaveButton() {
    if (this.currentAction == "edit") {
      let validation1 = this.editTenantName && this.editIndustryName;
      let validation2 =
        this.addTenantMode && this.selectedMarketSegment && this.editTenantName;
      let validation4 =
        this.addTenantMode ||
        (this.editTenantWithIndustry &&
          (this.editTenantWithIndustry.industryId >= 0 ||
            this.isUnknownTenant(this.editTenantWithIndustry.tenantName)));

      return (validation1 || validation2) && validation4;
    }

    if (this.currentAction == "moveOut") {
      return this.editMoveOutDate;
    }

    if (this.currentAction == "moveIn") {
      let validation1 =
        this.editTenantName && this.editIndustryName && this.editMoveOutDate;
      let validation2 =
        this.addTenantMode &&
        this.selectedMarketSegment &&
        this.editTenantName &&
        this.editMoveOutDate;
      let validation3 = true;
      let validation4 =
        this.addTenantMode ||
        (this.editTenantWithIndustry &&
          (this.editTenantWithIndustry.industryId >= 0 ||
            this.isUnknownTenant(this.editTenantWithIndustry.tenantName)));
      let validation5 = true;
      if (
        this.selectedTenant.actionMoveOut &&
        this.selectedTenant.actionMoveOut.dateAction
      ) {
        validation5 =
          this.editMoveOutDate &&
          this.editMoveOutDate > this.selectedTenant.actionMoveOut.dateAction;
      }
      if (
        this.splitSuite &&
        (this.newTenantArea > this.selectedTenant.area - 1 ||
          this.newTenantArea <= 0)
      ) {
        validation3 = false;
      }

      return (
        (validation1 || validation2) &&
        validation3 &&
        validation4 &&
        validation5
      );
    }
  }

  getDateTooltip() {
    if (
      this.selectedTenant.actionMoveOut &&
      this.selectedTenant.actionMoveOut.dateAction
    ) {
      if (
        this.editMoveOutDate &&
        this.editMoveOutDate <= this.selectedTenant.actionMoveOut.dateAction
      ) {
        return this.i18.get("tenants-property.cannotMoveInBeforeMoveOutDate");
      }
    }
    return null;
  }

  saveDataSingleTenant(selectedTenant: any) {
    //const actions = [];
    //const actionsEdit = selectedTenant.actionEdit;
    //const actionsMoveOut = selectedTenant.actionMoveOut;
    //const actionsMoveIn = selectedTenant.actionMoveIn;
    //actions.push(actionsEdit, actionsMoveOut, actionsMoveIn);
    this.saveTenant([selectedTenant]).then((resp: any) => {
      if (resp) {
        this.snackBar.open(
          this.i18.get("tenants-property.changesSavedSuccessfully"),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top"
          }
        );
        this.reloadTableData();
      }
    });
  }

  discardDataSingleTenant(selectedTenant: any) {
    //const actions = [];
    //const actionsEdit = selectedTenant.actionEdit;
    //const actionsMoveOut = selectedTenant.actionMoveOut;
    //const actionsMoveIn = selectedTenant.actionMoveIn;
    //actions.push(actionsEdit, actionsMoveOut, actionsMoveIn);
    this.discardTenantChanges(selectedTenant).then((resp: any) => {
      if (resp) {
        this.snackBar.open(
          this.i18.get("tenants-property.changesSavedSuccessfully"),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top"
          }
        );
        this.reloadTableData();
      }
    });
  }

  saveData() {
    // Get the list of actions from this.tenantsLastQuarter
    const actions = [];
    const actionsEdit = this.tenantsLastQuarter.map(
      tenant => tenant.actionEdit
    );
    const actionsMoveOut = this.tenantsLastQuarter.map(
      tenant => tenant.actionMoveOut
    );
    const actionsMoveIn = this.tenantsLastQuarter.map(
      tenant => tenant.actionMoveIn
    );
    const allActions = actions.concat(
      actionsEdit,
      actionsMoveOut,
      actionsMoveIn
    );
    if (allActions.length > 0) {
      this.saveTenantsData(allActions).then((resp: any) => {
        if (resp) {
          this.snackBar.open(
            this.i18.get("tenants-property.changesSavedSuccessfully"),
            "Close",
            {
              duration: 3000, // Duration in milliseconds
              horizontalPosition: "center",
              verticalPosition: "top"
            }
          );
          this.reloadTableData();
        }
      });
    }
  }

  openPropertyPage() {
    const propertyId = this.property.id;
    window.open(
      `${environment.applicationURL}/property/${propertyId}/lang/en`,
      "blank"
    );
  }

  discardAllChanges() {
    this.propertyService.restartTenantsConfig(this.config.id).then(resp => {
      this.snackBar.open(
        this.i18.get("tenants-property.allChangesRemoved"),
        "Close",
        {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: "center",
          verticalPosition: "top"
        }
      );
      this.reloadTableData();
    });
  }

  removeFromStats() {
    this.propertyService
      .markPropertyAsRemovedFromStats(this.config.id)
      .then(resp => {
        this.saveComments();
        this.snackBar.open(
          this.i18.get("tenants-property.propertyMarkedAsRemovedFromStats"),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top"
          }
        );
        this.reloadTableData();
      });
  }

  saveTenantsData(actions) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/tenant-actions`,
        this.tenantsLastQuarter
      )
      .toPromise();
  }

  saveTenant(tenant: any) {
    return this.http
      .post(`${environment.apiURL}/building-tenant/tenant-actions`, tenant)
      .toPromise();
  }

  discardTenantChanges(tenant: any) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/discard-tenant-actions`,
        tenant
      )
      .toPromise();
  }

  getGLAFromTenants() {
    let gla = 0;
    this.tenantsLastQuarter.forEach(tenant => {
      if (tenant.area) {
        gla += tenant.area;
      }
    });

    return gla;
  }

  shouldApplyRedBorder(tenantRow: any): boolean {
    return (
      tenantRow.actionMoveOut &&
      tenantRow.actionMoveOut.moveInSameQuarter &&
      !tenantRow.checkboxMoveIn
    );
  }

  getConfigStatus() {
    if (this.config) {
      // return this.config.status but in upppercase
      return this.config.status.toUpperCase();
    }

    return "";
  }

  validateSubmitAction() {
    if (
      this.tenantsLastQuarter.some(
        tenantRow =>
          tenantRow.actionMoveOut &&
          tenantRow.actionMoveOut.moveInSameQuarter &&
          !tenantRow.checkboxMoveIn
      )
    ) {
      return false;
    }
    return true;
  }

  getTenantsMissingAskingRent() {
    const tenantsMissingAskingRent = this.isMX()
      ? this.tenantsLastQuarter.filter(
          tenant =>
            tenant.actionMoveOut &&
            !tenant.actionMoveOut.askingRentPerAreaMXN &&
            !tenant.actionMoveOut.askingRentPerAreaUSD &&
            !tenant.actionMoveOut.moveInSameQuarter
        )
      : this.tenantsLastQuarter.filter(
          tenant =>
            tenant.actionMoveOut &&
            !tenant.actionMoveOut.askingRentPerArea &&
            !tenant.actionMoveOut.moveInSameQuarter
        );

    return tenantsMissingAskingRent;
  }

  checkIfAnyTenantIsNotReviewed() {
    return this.tenantsLastQuarter.some(tenant => !tenant.reviewed);
  }

  getSubmitButtonLabel() {
    switch (this.getConfigStatus()) {
      case "INITIAL":
        return this.i18.get("tenants-property.submitForLeadApproval");
      case "REJECTED":
        return this.i18.get("tenants-property.submitForLeadApproval");
      case "APPROVED":
        return this.i18.get("tenants-property.approvedByLead");
      case "SUBMITTED":
        return this.i18.get("tenants-property.submitted");
    }

    return "";
  }

  getTooltipTextForApproveButton() {
    if (
      this.getConfigStatus() == "APPROVED" ||
      this.getMarketConfigStatus() == "MARKET SUBMITTED" ||
      this.getMarketConfigStatus() == "APPROVED BY MGMT"
    ) {
      return this.i18.get("tenants-property.changesAlreadyApproved");
    } else {
      return null;
    }
  }

  isApproveButtonDisabled() {
    if (
      this.getConfigStatus() == "APPROVED" ||
      this.getMarketConfigStatus() == "MARKET SUBMITTED" ||
      this.getMarketConfigStatus() == "APPROVED BY MGMT"
    ) {
      return true;
    }
    return false;
  }

  isRejectButtonDisabled() {
    if (
      this.getMarketConfigStatus() == "MARKET SUBMITTED" ||
      this.getMarketConfigStatus() == "APPROVED BY MGMT"
    ) {
      return true;
    }
    return false;
  }

  getApproveButtonLabel() {
    if (this.getConfigStatus() == "APPROVED") {
      return this.i18.get("tenants-property.approvedByLead");
    }
    return this.i18.get("tenants-property.approve");
  }

  getConfigStatusLabel() {
    if (this.config.removedFromStats) {
      return (
        this.getConfigStatus().toUpperCase() + " - MARK AS REMOVED FROM STATS"
      );
    }

    return this.getConfigStatus();
  }

  isConfigInitialStatus() {
    return (
      this.getConfigStatus() == "INITIAL" ||
      this.getConfigStatus() == "REJECTED"
    );
  }

  getYearFromFirstTenant() {
    if (
      this.tenantsLastQuarter.length > 0 &&
      this.tenantsLastQuarter[0]?.dateEntered
    ) {
      const [year, month, day] = this.tenantsLastQuarter[0]?.dateEntered;
      const date = new Date(year, month - 1, day);
      let yearVal = date.getFullYear();
      let quarterVal = Math.floor((date.getMonth() + 3) / 3);
      if (quarterVal == 4) {
        yearVal += 1;
      }
      return yearVal;
    }
    return "";
  }

  getYearFromConfig() {
    if (this.config) {
      let quarter = this.config.quarter;
      let year = this.config.year;
      if (quarter == 4) {
        year += 1;
      }
      return year;
    }
    return "";
  }

  getQuarterFromConfig() {
    if (this.config) {
      let quarter = this.config.quarter;
      if (quarter == 4) {
        quarter = 1;
      } else {
        quarter += 1;
      }
      return quarter;
    }
    return "";
  }

  getQuarterFromFirstTenant() {
    if (
      this.tenantsLastQuarter.length > 0 &&
      this.tenantsLastQuarter[0]?.dateEntered
    ) {
      const [year, month, day] = this.tenantsLastQuarter[0]?.dateEntered;
      const date = new Date(year, month - 1, day);
      let quarter = Math.floor((date.getMonth() + 3) / 3);
      if (quarter == 4) {
        quarter = 1;
      } else {
        quarter += 1;
      }
      return quarter;
    }
    return;
  }

  getModalTitle() {
    if (this.currentAction == "edit") {
      return this.i18.get("tenants-property.editTenant");
    }
    if (this.currentAction == "moveOut") {
      return this.i18.get("tenants-property.moveOutTenant");
    }
    if (this.currentAction == "moveIn") {
      return this.i18.get("tenants-property.moveInTenant");
    }
  }

  reloadTableData() {
    this.propertyService
      .getLastQuarterTenantsInfo(this.id)
      .then((resp: any) => {
        this.tenantsLastQuarter = resp.buildingTenants;
        this.config = resp.config;
        this.comments = resp.comments || [];
        this.notes = resp.notes || [];
        this.updateCheckboxValues(this.tenantsLastQuarter);
      });
  }

  openConfirmationDialog(mode: number): void {
    this.confirmationDialogMode = mode;

    if (this.confirmationDialogMode == 1) {
      // Validate if there are tenants missing asking rent
      let tenantsMissingAskingRent = this.getTenantsMissingAskingRent();
      if (tenantsMissingAskingRent.length > 0) {
        let tenantWithError = this.getFloorOrModuleAndSuiteOrWarehouseFromTenant(
          tenantsMissingAskingRent[0]
        );
        this.snackBar.open(
          `Unable to submit because no asking rent per area was provided for tenant ${tenantWithError}.`,
          "Close",
          {
            duration: 5000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
        return;
      }
    }

    const dialogRef = this.dialog.open(this.confirmationDialog);

    dialogRef.afterClosed().subscribe(result => {});
  }

  getConfirmationDialogMessage() {
    if (this.confirmationDialogMode == 1) {
      return this.i18.get(
        "tenants-property.areYouSureYouWantToSubmitTheseChangesForApproval"
      );
    }
    if (this.confirmationDialogMode == 2) {
      return this.i18.get(
        "tenants-property.areYouSureYouWantToApproveTheseChanges"
      );
    }
    if (this.confirmationDialogMode == 3) {
      return this.i18.get(
        "tenants-property.areYouSureYouWantToMarkThisPropertyAsRemoved"
      );
    }
  }

  isConfirmButtonDisabled() {
    if (this.confirmationDialogMode == 3) {
      return this.newComment.length < 2;
    } else {
      return this.getConfigStatus() == "APPROVED";
    }
  }

  openCommentsDialog(): void {
    this.rejectionMode = false;
    const dialogRef = this.dialog.open(this.commentsDialog);

    dialogRef.afterClosed().subscribe(result => {});
  }

  openRejectionDialog(): void {
    this.rejectionMode = true;
    const dialogRef = this.dialog.open(this.commentsDialog);

    dialogRef.afterClosed().subscribe(result => {});
  }

  submitOrApprove(): void {
    switch (this.confirmationDialogMode) {
      case 1:
        this.submitChanges();
        break;
      case 2:
        this.approveChanges();
        break;
      case 3:
        this.removeFromStats();
        break;
    }
    this.dialog.closeAll();
  }

  submitChanges(): void {
    if (!this.validateSubmitAction()) {
      this.snackBar.open(
        this.i18.get("tenants-property.unableToSubmit"),
        "Close",
        {
          duration: 5000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["custom-snackbar"]
        }
      );
      return;
    }

    let tenantsMissingAskingRent = this.getTenantsMissingAskingRent();

    if (tenantsMissingAskingRent.length > 0) {
      let tenantWithError = this.getFloorOrModuleAndSuiteOrWarehouseFromTenant(
        tenantsMissingAskingRent[0]
      );
      this.snackBar.open(
        `Unable to submit because no asking rent per area was provided for tenant ${tenantWithError}.`,
        "Close",
        {
          duration: 5000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["custom-snackbar"]
        }
      );
      return;
    }

    // Disabled for now
    if (false && this.checkIfAnyTenantIsNotReviewed()) {
      this.snackBar.open(
        this.i18.get("tenants-property.tenantNotReviewed"),
        "Close",
        {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["custom-snackbar"]
        }
      );
      return;
    }

    this.propertyService
      .submitTenantChangesForApproval(this.config.id)
      .then(resp => {
        this.snackBar.open(
          this.i18.get("tenants-property.changesSubmittedForApproval"),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
        this.reloadTableData();
      });
    this.dialog.closeAll(); // Close the dialog
  }

  getFloorOrModuleAndSuiteOrWarehouseFromTenant(tenant: any): string {
    let floorOrModuleAndSuiteOrWarehouse = "";
    if (tenant.floorModule) {
      floorOrModuleAndSuiteOrWarehouse += tenant.floorModule;
    }
    if (tenant.suiteWarehouse) {
      floorOrModuleAndSuiteOrWarehouse += " - ";
      floorOrModuleAndSuiteOrWarehouse += tenant.suiteWarehouse;
    }
    return floorOrModuleAndSuiteOrWarehouse;
  }

  approveChanges(): void {
    this.propertyService.approveTenantChanges(this.config.id).then(resp => {
      this.snackBar.open(
        this.i18.get("tenants-property.changesAreNowApproved"),
        "Close",
        {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["custom-snackbar"]
        }
      );
      this.reloadTableData();
    });
    this.dialog.closeAll(); // Close the dialog
  }

  cancelSubmission(): void {
    this.dialog.closeAll(); // Close the dialog
  }

  formatComment(comment: any): string {
    const date = this.formatDates(comment.createdDate);
    return `${date} - ${comment.comment} - by ${comment.user.firstName} ${comment.user.lastName} `;
  }

  disableRejectButton() {
    // if all comments within comments array have id
    return this.comments.every(comment => comment.id);
  }

  addNewComment() {
    this.showNewCommentBox = false;
    this.comments.push({
      id: null,
      configId: this.config.id,
      comment: this.newComment,
      createdDate: new Date(),
      user: {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName
      }
    });
    this.newComment = "";
  }

  showNewComment() {
    this.showNewCommentBox = true;
    this.newComment = "";
  }

  rejectAndSaveComments() {
    if (this.newComment && this.newComment.length > 3) {
      this.addNewComment();
    }

    this.propertyService
      .rejectAndAddComments(this.config.id, this.comments)
      .then(resp => {
        this.snackBar.open(
          this.i18.get(
            "tenants-property.statusChangedToRejectedCommentsSavedSuccessfully"
          ),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );

        this.reloadTableData();
      });
    this.dialog.closeAll(); // Close the dialog
  }

  saveComments() {
    if (this.newComment && this.newComment.length > 3) {
      this.addNewComment();
    }
    this.propertyService
      .saveComments(this.config.id, this.comments)
      .then(resp => {
        this.snackBar.open(
          this.i18.get("tenants-property.commentsSavedSuccessfully"),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );

        this.reloadTableData();
      });
    this.dialog.closeAll(); // Close the dialog
  }

  openNotesDialog(): void {
    this.rejectionMode = false;
    const dialogRef = this.dialog.open(this.notesDialog);

    dialogRef.afterClosed().subscribe(result => {});
  }

  formatNote(note: any): string {
    const date = this.formatDates(note.createdDate);
    return `${date} - ${note.comment} - by ${note.user.firstName} ${note.user.lastName} `;
  }

  addNewNote() {
    this.showNewNoteBox = false;
    this.notes.push({
      buildingId: this.property.id,
      comment: this.newNote,
      createdDate: new Date(),
      user: {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName
      }
    });
    this.newNote = "";
  }

  showNewNote() {
    this.showNewNoteBox = true;
    this.newNote = "";
  }

  saveNotes() {
    if (this.newNote && this.newNote.length > 3) {
      this.addNewNote();
    }
    this.propertyService.saveNotes(this.property.id, this.notes).then(resp => {
      this.snackBar.open(
        this.i18.get("tenants-property.notesSavedSuccessfully"),
        "Close",
        {
          duration: 5000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "custom-snackbar"
        }
      );

      this.reloadTableData();
    });
    this.dialog.closeAll();
  }

  getMarketConfigStatus() {
    if (this.marketConfig) {
      return this.marketConfig.status.toUpperCase();
    }

    return "";
  }

  onMoveInSameQuarterChange(event: any) {
    if (event.target.checked) {
      this.editAskingRentUSD = null;
      this.editAskingRentMXN = null;
      this.disableCurrencyInput = true;
    } else {
      this.disableCurrencyInput = false;
    }
  }

  onReviewedChange(event: any, tenant: any) {
    this.propertyService
      .markTenantAsReviewed(tenant.id, event.target.checked)
      .then(resp => {
        this.snackBar.open(
          this.i18.get("tenants-property.tenantReviewed"),
          "Close",
          {
            duration: 2000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
      });
  }

  canAddMoreSplits(): boolean {
    return this.additionalSplits.length < this.maxAdditionalSplits;
  }

  getTotalSplitArea(): number {
    return this.additionalSplits.reduce(
      (sum, split) => sum + (split.area || 0),
      0
    );
  }

  getRemainingArea(): number {
    return this.originalArea - this.getTotalSplitArea();
  }

  getLetterSuffix(index: number): string {
    return String.fromCharCode(65 + index); // 65 is ASCII for 'A'
  }

  getRemainingAreaTooltip(): string {
    return this.selectedTenant.newArea > 0
      ? this.i18.get("tenants-property.suiteAreaOverwritten") +
          ` ${this.selectedTenant.area}, ` +
          this.i18.get("tenants-property.newArea") +
          `: ${this.selectedTenant.newArea}`
      : null;
  }

  addSplitRow() {
    if (!this.canAddMoreSplits()) return;

    if (this.additionalSplits.length === 0) {
      this.originalArea =
        this.selectedTenant.newArea ?? this.selectedTenant.area;
    }

    const baseSuite =
      this.property?.buildingType.id == 2001
        ? this.selectedTenant.suiteWarehouse
        : this.selectedTenant.floorModule;
    const letterSuffix = this.getLetterSuffix(this.additionalSplits.length);

    this.additionalSplits.push({
      id: Date.now(),
      floorModule:
        this.property?.buildingType.id == 2001
          ? this.selectedTenant.floorModule
          : `${baseSuite}-${letterSuffix}`,
      suiteWarehouse:
        this.property?.buildingType.id == 2001
          ? `${baseSuite}-${letterSuffix}`
          : this.selectedTenant.suiteWarehouse,
      area: null,
      tenantName: "",
      marketSegment: null,
      dateAction: new Date().toISOString().split("T")[0],
      askingRentMXN: null,
      askingRentUSD: null,
      moveInSameQuarter: false,
      addTenantFlag: false,
      addTenantMode: false,
      showAddTenants: false,
      isLoading: false,
      autocompleteResults: []
    });
  }

  removeSplitRow(index: number) {
    const removedArea = this.additionalSplits[index].area || 0;
    this.additionalSplits.splice(index, 1);

    //this.selectedTenant.area = this.originalArea - this.getTotalSplitArea();

    if (this.additionalSplits.length === 0) {
      //this.selectedTenant.area = this.originalArea;
      this.originalArea = this.selectedTenant.area;
    } else {
      this.reorganizeSuiteLetters();
    }
  }

  reorganizeSuiteLetters() {
    const baseSuite =
      this.property?.buildingType.id == 2001
        ? this.selectedTenant.suiteWarehouse
        : this.selectedTenant.floorModule;
    this.additionalSplits.forEach((split, index) => {
      if (this.property?.buildingType.id == 2001) {
        split.suiteWarehouse = `${baseSuite}-${this.getLetterSuffix(index)}`;
      } else {
        split.floorModule = `${baseSuite}-${this.getLetterSuffix(index)}`;
      }
    });
  }

  onSplitAreaChange(event: any, index: number) {
    const newArea = parseFloat(event.target.value);
    const currentSplitTotal =
      this.getTotalSplitArea() - (this.additionalSplits[index].area || 0);

    if (currentSplitTotal + newArea > this.originalArea) {
      this.snackBar.open(
        this.i18.get("tenants-property.totalAreaExceeded"),
        "Close",
        {
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top"
        }
      );
      this.additionalSplits[index].area = null;
    } else {
      this.additionalSplits[index].area = newArea;
      //this.selectedTenant.area = this.originalArea - this.getTotalSplitArea();
    }
  }

  onInputChangeAdditionalSplit(event: any, split: any) {
    split.tenantName = event.target.value;
    split.isLoading = true;

    this.propertyService
      .fetchTenantsAutocompleteResults(event.target.value)
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(results => {
        // Add Unknown option if input partially matches 'unknown'
        if (
          "unknown".includes(event.target.value.toLowerCase()) ||
          this.isUnknownTenant(event.target.value)
        ) {
          const unknownOption = {
            tenantName: "Unknown",
            industryId: null,
            industryName: null
          };
          split.autocompleteResults = [unknownOption, ...results];
        } else {
          split.autocompleteResults = results;
        }

        split.isLoading = false;
        split.showAddTenants = true;
      });
  }

  onSelectOptionAdditionalSplit(option: any, split: any) {
    split.tenantName = option.tenantName;
    if (option.corporationId) {
      split.corporation = {
        id: option.corporationId,
        name: option.tenantName
      };
    }
    if (
      option.tenantName === "Unknown" ||
      this.isUnknownTenant(option.tenantName)
    ) {
      split.marketSegment = null;
      split.addTenantMode = true;
      split.autocompleteResults = [];
      split.showAddTenants = false;
      return;
    }

    // Find market segment from existing list by industryId
    if (option.industryId) {
      split.marketSegment = this.marketSegments.find(
        segment => segment.id === option.industryId
      );
    } else if (option.marketSegment) {
      split.marketSegment = option.marketSegment;
    } else if (option.industry) {
      split.marketSegment = option.industry;
    }

    split.addTenantMode = true;
    split.autocompleteResults = [];
    split.showAddTenants = false;
  }

  addTenantToAdditionalSplit(split: any) {
    split.addTenantFlag = true;
  }

  onAddNewTenantToAdditionalSplit(split: any) {
    split.tenantName = split.tenantName;
    split.marketSegment = null;
    split.addTenantMode = true;
    split.showAddTenants = false;
  }

  onBackToVacantAdditionalSplit(split: any) {
    split.tenantName = "Vacant";
    split.addTenantMode = false;
    split.addTenantFlag = false;
    split.marketSegment = null;
  }

  openFloorAreaModal(): void {
    this.selectedAreaType = "floor"; // Always start with floor area selected

    this.propertyService
      .getFloorExpansionData(this.config.id, this.property.id)
      .then(resp => {
        this.floorAreas = resp;
        this.initialFloorAreas = JSON.parse(JSON.stringify(resp)); // Store deep copy of initial state
        this.dialog.open(this.floorAreaModal, {
          width: "800px",
          disableClose: true,
          autoFocus: false,
          panelClass: ["custom-modal-class-tenants", "tooltip-above-modal"]
        });
      });
  }

  saveFloorOrSuiteAreas(): void {
    if (this.selectedAreaType === "floor") {
      /* if (
        this.floorAreas.some(
          floor => floor.newArea > 0 && floor.newArea <= floor.currentArea
        )
      ) {
        this.snackBar.open(
          this.i18.get("tenants-property.floorAreasNotValid"),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
        return;
      } */

      this.clickSaveFloorAreas();
    } else {
      this.saveSuiteAreas();
    }
  }

  saveSuiteAreas(): void {
    // For each floor do the following:
    // 1. Validate that the sum of the new areas that match the floor id is equal to the floor area
    let showError = false;
    let totalNewArea = 0;
    this.floorAreas.forEach(floor => {
      const floorModule = floor.floor;

      let suiteAreasWithFloorModule = this.suiteAreas.filter(
        suite => suite.floorModule === floorModule
      );

      // if all suiteAreasWithFloorModule have newArea 0 or null, then skip this floor
      if (
        suiteAreasWithFloorModule.every(
          suite => suite.newArea === 0 || suite.newArea === null
        )
      ) {
        return;
      }

      let floorArea =
        suiteAreasWithFloorModule[0].newFloorArea ??
        suiteAreasWithFloorModule[0].floorArea;
      totalNewArea = suiteAreasWithFloorModule.reduce(
        (sum, suite) => sum + (suite.newArea || 0),
        0
      );

      // validate that totalNewArea is equal to floorArea with a tolerance of 0.01
      if (Math.abs(totalNewArea - floorArea) > 0.01) {
        showError = true;
      }
    });

    if (showError) {
      this.snackBar.open(
        this.i18.get("tenants-property.suiteAreasNotValid"),
        "Close",
        {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["custom-snackbar"]
        }
      );
      return;
    }

    this.propertyService
      .saveSuiteAreasData(this.property.id, this.suiteAreas)
      .then(resp => {
        this.snackBar.open(
          this.i18.get("tenants-property.suiteAreasSavedSuccessfully"),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
        this.dialog.closeAll();
        this.reloadTableData();
      });
  }

  saveFloorAreas(closeModal: boolean = false): void {
    // validate if all floors have a new area that is greater than the current area
    /*  if (
      this.floorAreas.some(
        floor => floor.newArea > 0 && floor.newArea <= floor.currentArea
      )
    ) {
      this.snackBar.open(
        this.i18.get("tenants-property.floorAreasNotValid"),
        "Close",
        {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["custom-snackbar"]
        }
      );
      return;
    } */

    this.propertyService
      .saveFloorExpansionData(this.config.id, this.property.id, this.floorAreas)
      .then(resp => {
        // set new initialFloorAreas
        this.initialFloorAreas = JSON.parse(JSON.stringify(this.floorAreas));
        this.snackBar.open(
          this.i18.get("tenants-property.floorAreasSavedSuccessfully"),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
        if (closeModal) {
          this.dialog.closeAll();
          this.reloadTableData();
        }
      });
  }

  discardFloorAreas(): void {
    this.floorAreas = [];
    this.propertyService
      .discardFloorExpansionData(this.config.id, this.property.id)
      .then(resp => {
        this.snackBar.open(
          this.i18.get("tenants-property.floorAreasDiscarded"),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
      });

    this.dialog.closeAll();
  }

  updateSpaceConditionId(event) {
    this.conditionSelected = this.conditions.filter(
      condition => condition.id == event
    )[0];
  }

  openAskingRentModal(): void {
    // Dummy data - this will be replaced with API call
    this.propertyService.getAskingRentData(this.property.id).then(resp => {
      this.askingRentData = resp;
      this.askingRentRows = resp.buildingTenants;
      this.maintenanceFee = resp.maintenanceFee;
    });

    this.dialog.open(this.askingRentModal, {
      width: "800px",
      disableClose: true,
      autoFocus: false,
      panelClass: ["custom-modal-class-tenants", "tooltip-above-modal"]
    });
  }

  saveAskingRent(): void {
    this.propertyService
      .saveAskingRentData(this.property.id, this.askingRentData)
      .then(resp => {
        this.snackBar.open(
          this.i18.get("tenants-property.askingRentSavedSuccessfully"),
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
      });
    this.dialog.closeAll();
  }

  discardAskingRent(): void {
    this.askingRentRows = [];
    this.askingRentData = null;
    this.maintenanceFee = 0;
    this.propertyService.discardAskingRentData(this.property.id).then(resp => {
      this.snackBar.open(
        this.i18.get("tenants-property.askingRentDiscarded"),
        "Close",
        {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["custom-snackbar"]
        }
      );
    });
    this.dialog.closeAll();
  }

  printData(): void {
    window.print();
  }

  getFloorModuleLabel(label): string {
    if (this.isMobile) {
      const translatedValue = this.translate.instant(label);
      return translatedValue.replace("/", "/<br>");
    } else {
      return this.translate.instant(label);
    }
  }

  // Add this method to switch between area types
  switchAreaType(type: "floor" | "suite") {
    if (this.hasUnsavedFloorChanges()) {
      this.pendingAreaType = type;
      this.openUnsavedChangesModal();
      return;
    }
    this.proceedWithAreaTypeSwitch(type);
  }

  calculateSuiteAreas(floorArea?: number): void {
    const floorModule =
      this.property.buildingType.id == 2001
        ? this.selectedSuite.floorModule
        : this.selectedSuite.suiteWarehouse;

    // Get all suites in the same floorModule
    const suites = this.suiteAreas.filter(
      suite =>
        (this.property.buildingType.id == 2001 &&
          suite.floorModule === floorModule) ||
        (this.property.buildingType.id == 1001 &&
          suite.suiteWarehouse === floorModule)
    );

    // Compute total old area (excluding suites with newArea = 0)
    const totalOldArea = suites.reduce((sum, suite) => {
      // If suite has newArea = 0, don't include it in calculations
      if (suite.id !== this.selectedSuite.id && suite.newArea === 0) {
        return sum;
      }
      return sum + suite.area;
    }, 0);

    // Compute how much additional area the selected suite is taking
    const additionalArea = this.selectedSuite.newArea - this.selectedSuite.area;

    //floorArea = this.selectedSuite.newFloorArea ?? totalOldArea;
    floorArea = this.selectedSuite.newFloorArea ?? this.selectedSuite.floorArea;

    if (suites.length === 1) {
      this.selectedSuite.newArea = floorArea;
      return;
    }

    // validate that this.selectedSuite.newArea is not greater than floorArea
    if (this.selectedSuite.newArea > floorArea) {
      this.selectedSuite.newArea = floorArea;
    }

    // Calculate total area of suites with newArea = 0
    const totalZeroArea = suites.reduce((sum, suite) => {
      if (suite.id !== this.selectedSuite.id && suite.newArea === 0) {
        return sum + suite.area;
      }
      return sum;
    }, 0);

    // Compute the new total floor area available for non-zero suites
    //const targetFloorArea = floorArea - totalZeroArea;
    const targetFloorArea = floorArea;

    // Compute the total area available for redistribution (excluding selectedSuite and zero-area suites)
    const totalOldAreaWithoutSelected = totalOldArea - this.selectedSuite.area;
    const totalNewAreaWithoutSelected =
      targetFloorArea - this.selectedSuite.newArea;

    // Avoid division by zero in edge cases
    if (totalOldAreaWithoutSelected <= 0) return;

    // Update newArea for each suite (except selectedSuite and those with newArea = 0)
    suites.forEach(suite => {
      if (suite.id !== this.selectedSuite.id) {
        if (suite.newArea === 0) {
          // Keep the suite area at 0
          suite.newArea = 0;
        } else {
          // Reduce proportionally based on the new floor area
          suite.newArea =
            suite.area *
            (totalNewAreaWithoutSelected / totalOldAreaWithoutSelected);
          suite.newArea = parseFloat(suite.newArea.toFixed(2));
        }
      }
    });

    // Ensure selectedSuite keeps its defined newArea
    this.selectedSuite.newArea = parseFloat(
      this.selectedSuite.newArea.toFixed(2)
    );
  }

  // Add these new methods
  private hasUnsavedFloorChanges(): boolean {
    return this.floorAreas?.some(
      (floor, index) => floor.newArea !== this.initialFloorAreas[index].newArea
    );
  }

  private openUnsavedChangesModal(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      width: "400px",
      disableClose: true,
      autoFocus: true,
      panelClass: ["custom-modal-class-tenants", "confirmation-dialog-custom"],
      data: {
        message: this.i18.get("tenants-property.changesInFloorAreas"),
        onConfirm: () => {
          this.propertyService
            .saveFloorExpansionData(
              this.config.id,
              this.property.id,
              this.floorAreas
            )
            .then(resp => {
              // set new initialFloorAreas
              this.initialFloorAreas = JSON.parse(
                JSON.stringify(this.floorAreas)
              );
              this.snackBar.open(
                this.i18.get("tenants-property.floorAreasSavedSuccessfully"),
                "Close",
                {
                  duration: 3000, // Duration in milliseconds
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  panelClass: ["custom-snackbar"]
                }
              );
              this.proceedWithAreaTypeSwitch(this.pendingAreaType);
            });
        },
        onCancel: () => {
          this.cancelSwitch();
        }
      }
    });
  }

  clickSaveFloorAreas(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      width: "400px",
      disableClose: true,
      autoFocus: true,
      panelClass: ["custom-modal-class-tenants", "confirmation-dialog-custom"],
      data: {
        message: this.i18.get(
          "tenants-property.changesInFloorAreasConfirmation"
        ),
        onConfirm: () => {
          this.saveFloorAreas(true);
        }
      }
    });
  }

  confirmSwitch(): void {
    this.saveFloorAreas();
    this.proceedWithAreaTypeSwitch(this.pendingAreaType);
    this.dialog.closeAll();
  }

  cancelSwitch(): void {
    this.pendingAreaType = "floor";
    // restore initial floor areas
    this.floorAreas = JSON.parse(JSON.stringify(this.initialFloorAreas));
    //if (this.dialogRef) {
    //  this.dialogRef.close();
    //}
  }

  private proceedWithAreaTypeSwitch(type: "floor" | "suite"): void {
    this.selectedAreaType = type;
    if (type === "suite") {
      this.pauseCalculations = false; // Reset checkbox when switching to suite area
    }
    this.selectedAreaType = type;

    if (type === "suite") {
      this.propertyService.getTenantsData(this.property.id).then(resp => {
        this.suiteAreas = resp;
      });
    }
  }

  discardSuiteAreaChanges(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      width: "400px",
      disableClose: true,
      autoFocus: true,
      panelClass: ["custom-modal-class-tenants", "confirmation-dialog-custom"],
      data: {
        message: this.i18.get("tenants-property.discardSuiteAreasConfirmation"),
        onConfirm: () => {
          this.suiteAreas = [];
          this.propertyService
            .discardSuiteAreasData(this.property.id)
            .then(resp => {
              this.snackBar.open(
                this.i18.get("tenants-property.suiteAreasDiscarded"),
                "Close",
                {
                  duration: 3000,
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  panelClass: ["custom-snackbar"]
                }
              );
              this.dialog.closeAll();
              this.reloadTableData();
            });
        }
      }
    });
  }

  isUnknownTenant(tenantName: string): boolean {
    return (
      tenantName.toLowerCase() === "unknown" ||
      tenantName.toLowerCase() === "desconhecido"
    );
  }

  // Add new methods for flags functionality
  openFlagsDialog(): void {
    const dialogRef = this.dialog.open(this.flagsDialog);

    dialogRef.afterClosed().subscribe(result => {});
  }

  formatFlag(flag: BuildingFlag): string {
    let userName = flag.user?.firstName + " " + flag.user?.lastName;
    return `${this.datePipe.transform(
      flag.createdDate,
      "MM/dd/yyyy HH:mm"
    )} - ${userName}: ${flag.comment}`;
  }

  showNewFlag() {
    this.showNewFlagBox = true;
  }

  addNewFlag() {
    if (this.newFlag && this.newFlag.length >= 10) {
      const newFlag: BuildingFlag = {
        id: null,
        configId: this.config.id,
        comment: this.newFlag,
        createdDate: new Date(),
        user: {
          id: this.user.id,
          firstName: this.user.firstName,
          lastName: this.user.lastName
        }
      };
      this.flags.push(newFlag);
      this.newFlag = "";
      this.showNewFlagBox = false;
    }
  }

  saveFlags() {
    // This method will be implemented later when the API is ready
    if (this.newFlag && this.newFlag.length >= 5) {
      this.addNewFlag();
    }
    this.propertyService
      .saveBuildingFlags(this.config.id, this.flags)
      .then(resp => {
        this.snackBar.open(
          this.i18.get("tenants-property.flagsSavedSuccessfully"),
          "Close",
          {
            duration: 3000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["custom-snackbar"]
          }
        );
        this.dialog.closeAll();
      });
  }

  hasAutomaticFlags(): boolean {
    return (
      this.automaticFlags &&
      (this.automaticFlags.hasFloorExpansion ||
        this.automaticFlags.hasSplitSuite ||
        this.automaticFlags.hasSuiteAreaChange ||
        this.automaticFlags.removedFromStats ||
        this.automaticFlags.hasActionOutsideCurrentQuarter)
    );
  }
}
