<h2 *ngIf="tenantForm.get('name')?.value" mat-dialog-title class="modal-header">
  {{ "master-tenant.edit" | translate }}: {{ tenantForm.get("name")?.value }}
  <button mat-icon-button class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<h2
  *ngIf="!tenantForm.get('name')?.value"
  mat-dialog-title
  class="modal-header"
>
  {{ "master-tenant.new" | translate }}
  <button mat-icon-button class="close-button" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content class="modal-content">
  <form [formGroup]="tenantForm" class="tenant-form">
    <table class="tenant-table">
      <thead>
        <tr>
          <th>{{ "master-tenant.tenantName" | translate }}</th>
          <th>{{ "master-tenant.industry" | translate }}</th>
          <th>{{ "master-tenant.country" | translate }}</th>
          <th>{{ "master-tenant.website" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <input
              matInput
              formControlName="name"
              class="table-input form-control"
            />
          </td>
          <td>
            <select
              formControlName="industry"
              [compareWith]="compareIndustries"
              class="form-select"
            >
              <option *ngFor="let industry of industries" [ngValue]="industry"
                >{{ industry.name | langSelector }}
              </option>
            </select>
          </td>

          <td>
            <select formControlName="country" class="form-select">
              <option [ngValue]="null">{{
                "master-tenant.none" | translate
              }}</option>
              <option *ngFor="let country of countries" [ngValue]="country"
                >{{ country | langSelector }}
              </option>
            </select>
          </td>
          <td>
            <input
              matInput
              formControlName="contactUrl"
              class="table-input form-control"
              style="min-width: 190px;"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table class="tenant-table">
      <thead>
        <tr>
          <th>{{ "master-tenant.contactName" | translate }}</th>
          <th>{{ "master-tenant.contactPhone" | translate }}</th>
          <th>{{ "master-tenant.contactEmail" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <input
              matInput
              formControlName="contactName"
              class="table-input form-control"
            />
          </td>
          <td>
            <input
              matInput
              formControlName="contactPhone"
              class="table-input form-control"
            />
          </td>
          <td>
            <input
              matInput
              formControlName="contactEmail"
              class="table-input form-control"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="modal-actions">
  <button mat-button (click)="close()" class="close-btn">
    {{ "master-tenant.close" | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="save()"
    [disabled]="tenantForm.invalid"
    class="save-btn"
  >
    {{
      isCreating
        ? ("master-tenant.save" | translate)
        : ("master-tenant.update" | translate)
    }}
  </button>
</mat-dialog-actions>
