<div class="nav-container">
  <div class="col-md-12 color-span nav-detail">
    <div class="col-md-12 title-row f-left mt-1">
      <span class="title">
        {{ "tenants-property.statsDashboard" | translate }}
      </span>
    </div>
  </div>
</div>
<div class="content">
  <app-loading-spinner *ngIf="!showDetail"></app-loading-spinner>
  <div *ngIf="showDetail" class="content-detail">
    <wait-spinner *ngIf="showSpinner"></wait-spinner>
    <div class="col-md-12 mt-4 ">
      <div class=" key-columns">
        <div class="col-md-6" [ngClass]="{ 'p-0': isMobile }">
          <div class="col-md-12 key-facts mt-2">
            {{ "tenants-property.marketSelection" | translate }}
          </div>
          <div class="d-flex key-facts-columns border-table">
            <div class="column label-color">
              {{ "tenants-property.year" | translate }}
            </div>
            <div class="column label-color2">
              {{ getYearFromMarketConfig() }}
            </div>
            <div class="column label-color">
              {{ "tenants-property.period" | translate }}
            </div>
            <div class="column label-color2">
              Q{{ getQuarterFromMarketConfig() }}
            </div>
            <div class="column label-color">
              {{ "tenants-property.market" | translate }}
            </div>
            <div class="column label-color2">
              <select
                [(ngModel)]="selectedMarketConfig"
                style="width: 100%;"
                (ngModelChange)="onMarketChange($event)"
              >
                <option *ngFor="let market of marketConfigs" [ngValue]="market">
                  {{ market.marketName | langSelector }}
                </option>
              </select>
            </div>
            <div class="column label-color">
              {{ "tenants-property.status" | translate }}
            </div>
            <div class="column label-color2">
              {{ getConfigStatusLabel() }}
            </div>
            <div class="column label-color" *ngIf="isResearchAdmin && isMX()">
              {{ "tenants-property.csvWithChanges" | translate }}
            </div>
            <div class="column label-color2" *ngIf="isResearchAdmin && isMX()">
              <label
                style="color: #007bff; cursor: pointer; text-decoration: underline; text-transform: uppercase;"
                (click)="downloadStatsCsvByMarket()"
                [class.disabled]="showSpinner"
              >
                {{
                  showSpinner
                    ? ("tenants-property.downloading" | translate)
                    : ("tenants-property.download" | translate)
                }}
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-6" [ngClass]="{ 'p-0': isMobile }">
          <div class="col-md-12 key-facts mt-2">
            {{ "tenants-property.actions" | translate }}
          </div>
          <div
            class="key-facts-columns border-table"
            style="height: 163px; display: flex;
          align-content: center;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;"
            *ngIf="isResearchCountryManager"
          >
            <div
              class="column label-color"
              style="text-align: center; display: flex;
              align-items: center;
              justify-content: center;"
              *ngIf="getConfigStatus() == 'MARKET SUBMITTED'"
            >
              <button
                class="btn btn-primary reject-button"
                [disabled]="getConfigStatus() != 'MARKET SUBMITTED'"
                (click)="openRejectionDialog()"
              >
                {{ "tenants-property.reject" | translate }}
              </button>
            </div>
            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
            >
              <button
                class="btn btn-primary approve-button"
                [disabled]="getConfigStatus() != 'MARKET SUBMITTED'"
                (click)="openConfirmationDialog(2)"
              >
                {{ getApproveButtonLabel() }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                !comments || comments.length == 0
                  ? 'No comments available.'
                  : null
              "
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary view-comments-button"
                (click)="openCommentsDialog()"
                [disabled]="!comments || comments.length == 0"
              >
                {{ "tenants-property.viewComments" | translate }}
              </button>
            </div>
          </div>

          <div
            class="key-facts-columns border-table"
            style="height: 163px; display: flex;
          align-content: space-between;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;"
            *ngIf="!isResearchAdmin"
          >
            <div class="column label-color">
              {{ "tenants-property.researcher" | translate }}
            </div>
            <div class="column label-color2">
              {{ selectedMarketConfig.researcherName }}
            </div>
            <div class="column label-color">
              {{ "tenants-property.researchLead" | translate }}
            </div>
            <div class="column label-color2">
              {{ selectedMarketConfig.leadResearcherName }}
            </div>
            <div class="column label-color">
              {{ "tenants-property.countryManager" | translate }}
            </div>
            <div class="column label-color2">
              {{ selectedMarketConfig.countryManagerName }}
            </div>
          </div>

          <div
            class="key-facts-columns border-table"
            style="height: 163px; display: flex;
          align-content: center;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;"
            *ngIf="isResearchLead"
          >
            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
            >
              <button
                class="btn btn-primary approve-button"
                [disabled]="
                  getConfigStatus() != 'WAITING FOR MARKET SUBMISSION'
                "
                (click)="openConfirmationDialog(1)"
              >
                {{ getSubmitButtonLabel() }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                !comments || comments.length == 0
                  ? 'No comments available.'
                  : null
              "
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary view-comments-button"
                [disabled]="!comments || comments.length == 0"
                (click)="openCommentsDialog()"
              >
                {{ "tenants-property.viewComments" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 tenantheader">
        <div class="col-md-12 key-facts">
          {{ "tenants-property.researchConfiguration" | translate }}
        </div>

        <div class="table-unitmix">
          <table class="table" id="buildingTable">
            <thead class="head-vacant title-row unitmixheaders">
              <tr>
                <th style="text-align: left;">
                  {{ "tenants-property.propertyName" | translate }}
                  <div style="padding-top: 10px;">
                    <input
                      type="text"
                      placeholder="{{
                        'tenants-property.filterByPropertyName' | translate
                      }}"
                      [(ngModel)]="propertyNameFilter"
                      (input)="filterResearchData()"
                      style="border-radius: 4px; font-size: 15px;"
                    />
                  </div>
                </th>
                <th style="text-align: left;">
                  {{ "tenants-property.tenantStatsLink" | translate }}
                  <div style="height: 35px;"></div>
                </th>
                <th style="text-align: left; width: 100px;">
                  {{ "tenants-property.siteVisit" | translate }}
                  <div style="height: 35px; padding-top: 10px;">
                    <select
                      [(ngModel)]="siteVisitFilter"
                      (change)="filterResearchData()"
                    >
                      <option value="ALL">All</option>
                      <option value="YES">Yes</option>
                      <option value="NO">No</option>
                    </select>
                  </div>
                </th>
                <th style="text-align: left;">
                  {{ "tenants-property.changes" | translate }}
                  <div style="height: 35px; padding-top: 10px;">
                    <select
                      [(ngModel)]="changesFilter"
                      (change)="filterResearchData()"
                    >
                      <option value="ALL">All</option>
                      <option value="YES">Yes</option>
                      <option value="NO">No</option>
                    </select>
                  </div>
                </th>
                <th style="text-align: left; width: 180px;">
                  {{ "tenants-property.researcher" | translate }}
                  <div style="height: 35px;"></div>
                </th>
                <th
                  style="text-align: left; width: 180px;"
                  *ngIf="!isResearchAdmin"
                >
                  {{ "tenants-property.leadApproval" | translate }}
                  <div style="height: 35px;"></div>
                </th>
                <th style="text-align: left; width: 180px;">
                  {{ "tenants-property.status" | translate }}
                  <div style="height: 35px; padding-top: 10px;">
                    <select
                      [(ngModel)]="statusFilter"
                      (change)="filterResearchData()"
                    >
                      <option
                        *ngFor="let status of getStatusList()"
                        [ngValue]="status.value"
                      >
                        {{ status.viewValue }}
                      </option></select
                    >
                  </div>
                </th>
              </tr>
            </thead>
            <tbody
              class="border-table title-row unitmixtablerows"
              style="text-align: left; font-size: small;"
            >
              <tr *ngFor="let data of filteredData; let i = index">
                <td>
                  <a
                    href="{{ getBuildingDetailURL(data.buildingId) }}"
                    target="_blank"
                    >{{ getBuildingNameWithType(data) }}</a
                  >
                </td>
                <td>
                  <a
                    href="{{ getBuildingTenantChangesURL(data.buildingId) }}"
                    target="_blank"
                    >{{ getBuildingTenantChangesURL(data.buildingId) }}</a
                  >
                </td>
                <td style="text-align: center;">
                  <span style="padding-right: 5px;">{{
                    data.siteVisit ? "YES" : "NO"
                  }}</span>
                  <mat-checkbox
                    [checked]="data.siteVisit"
                    (change)="onSiteVisitChange($event, data)"
                    color="primary"
                    [disabled]="!isResearchAdmin && !isResearcher"
                    style="font-size: 5px;"
                  >
                  </mat-checkbox>
                </td>
                <td style="text-align: center;">
                  {{ data.hasChanges ? "YES" : "NO" }}
                </td>
                <td>
                  <select
                    [(ngModel)]="data.researcherName"
                    (ngModelChange)="
                      onResearcherSelected($event, data.buildingId)
                    "
                    *ngIf="isResearchAdmin"
                    style="width: 100%;"
                  >
                    <option
                      *ngFor="let user of researchUsers"
                      [ngValue]="user.name"
                    >
                      {{ user.name }}
                    </option>
                  </select>
                  <span *ngIf="!isResearchAdmin">{{
                    data.researcherName
                  }}</span>
                </td>
                <td *ngIf="!isResearchAdmin">
                  {{ data.leadResearcherName }}
                </td>
                <td>
                  {{ getStatusLabel(data.buildingMarketTenantStatus) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- NgbModal Template -->
<ng-template #chartModal let-modal>
  <div class="custom-modal custom-modal-centered modal-80vh">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ getModalTitle() }}
      </h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closeModal()"
      ></button>
    </div>
    <div class="modal-body">
      <div class="table-tenant-popup">
        <div class="d-flex" *ngIf="currentAction == 'moveIn'">
          <div class="form-check form-switch" style="padding-left: 2.5em;">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              [(ngModel)]="splitSuite"
            />
            <label class="form-check-label" for="flexSwitchCheckDefault"
              >Split Suite</label
            >
          </div>
        </div>
        <table class="table" style="height: 400px;">
          <thead
            class="head-vacant"
            style="text-align: center; background-color: #5a86a3;"
          >
            <tr>
              <th style="width: 80px;">
                Floor / Module
              </th>
              <th style="max-width: 100px;">
                Suite / Warehouse
              </th>
              <th style="max-width: 100px;">
                Area (m²)
              </th>
              <th style="min-width: 200px;">
                Tenant
              </th>
              <th
                *ngIf="currentAction == 'edit' || currentAction == 'moveIn'"
                style="max-width: 120px;"
              >
                Industry
              </th>
              <th
                *ngIf="currentAction == 'moveOut' || currentAction == 'moveIn'"
                style="max-width: 120px;"
              >
                Date
              </th>
              <th *ngIf="currentAction == 'moveOut'" style="max-width: 120px;">
                Asking Rent MXN
              </th>
              <th *ngIf="currentAction == 'moveOut'" style="max-width: 120px;">
                Asking Rent USD
              </th>
            </tr>
          </thead>
          <tbody style="text-align: center;">
            <tr style="height: 50px;">
              <td>
                {{ selectedTenant.floorModule }}
              </td>
              <td>
                {{ selectedTenant.suiteWarehouse }}
              </td>
              <td *ngIf="!splitSuite">
                {{ selectedTenant.area | localNumber: "1.2-2" }}
              </td>
              <td *ngIf="splitSuite">
                <input
                  type="number"
                  [(ngModel)]="newTenantArea"
                  style="width: 120px;"
                  min="0"
                  max="{{ selectedTenant.area }}"
                />
              </td>
              <td>
                <div
                  style="position: relative; text-align: start;"
                  *ngIf="currentAction != 'edit' && currentAction != 'moveIn'"
                >
                  <span> {{ editTenantName }} </span>
                </div>
                <div
                  style="position: relative; text-align: start; width: 100%;"
                  *ngIf="currentAction == 'edit' || currentAction == 'moveIn'"
                >
                  <span *ngIf="addTenantMode"> {{ editTenantName }} </span>
                  <input
                    *ngIf="!addTenantMode"
                    type="text"
                    [(ngModel)]="editTenantName"
                    (input)="onInputChange($event)"
                    placeholder="Type to search..."
                    [ngClass]="{ loading: isLoading }"
                    style="width: 100%;"
                  />
                  <!-- Loading icon, only shown when isLoading is true -->
                  <span *ngIf="isLoading" class="loading-icon">🔄</span>

                  <!-- Autocomplete dropdown -->
                  <ul
                    *ngIf="autocompleteResults.length && !isLoading"
                    class="autocomplete-dropdown"
                    style="z-index: 1;"
                  >
                    <li
                      *ngFor="let option of autocompleteResults"
                      (click)="onSelectOption(option)"
                    >
                      {{ option.tenantName }}
                    </li>
                  </ul>
                </div>
                <div
                  style="text-align: left; font-size: smaller;"
                  *ngIf="showAddTenants && !addTenantMode"
                >
                  <label
                    (click)="onAddTenant()"
                    style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                  >
                    Add as new tenant
                  </label>
                </div>
                <div
                  style="text-align: left; font-size: smaller;"
                  *ngIf="addTenantMode"
                >
                  <label
                    (click)="onBackToSearchTenant()"
                    style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                  >
                    Back to search
                  </label>
                </div>
              </td>
              <td *ngIf="currentAction == 'edit' || currentAction == 'moveIn'">
                <span *ngIf="editIndustryName && !addTenantMode">
                  {{ editIndustryName | langSelector }}</span
                >
                <select
                  [(ngModel)]="selectedMarketSegment"
                  style="width: 100%;"
                  *ngIf="addTenantMode"
                >
                  <option
                    *ngFor="let segment of marketSegments"
                    [ngValue]="segment"
                  >
                    {{ segment.name | langSelector }}
                  </option>
                </select>
              </td>
              <td
                *ngIf="currentAction == 'moveOut' || currentAction == 'moveIn'"
              >
                <input
                  type="date"
                  [(ngModel)]="editMoveOutDate"
                  style="width: 120px;"
                />
              </td>
              <td *ngIf="currentAction == 'moveOut'">
                <input
                  type="number"
                  [(ngModel)]="editAskingRentMXN"
                  style="width: 120px;"
                />
              </td>
              <td *ngIf="currentAction == 'moveOut'">
                <input
                  type="number"
                  [(ngModel)]="editAskingRentUSD"
                  style="width: 120px;"
                />
              </td>
            </tr>
            <tr *ngIf="splitSuite" style="height: 50px;">
              <td>
                {{ selectedTenant.floorModule }}
              </td>
              <td>{{ selectedTenant.suiteWarehouse }}-A</td>
              <td>
                {{ selectedTenant.area - newTenantArea | localNumber: "1.2-2" }}
              </td>
              <td>
                <div style="position: relative; text-align: start;">
                  <span> Vacant </span>
                </div>
              </td>
              <td>
                <span></span>
              </td>
              <td>
                <span></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="discardChanges()"
      >
        Discard changes
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!validateSaveButton()"
        (click)="saveChanges()"
      >
        Save
      </button>
      <button type="button" class="btn btn-secondary" (click)="closeModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>
<ng-template #commentsDialog>
  <div
    class="modal-header"
    style="padding: 0; border-bottom: 0; padding-bottom: 30px;"
  >
    <h4 class="modal-title" style="font-weight: 600;">
      Comments
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="cancelSubmission()"
    ></button>
  </div>
  <div mat-dialog-content style="width: 550px;">
    <div style="max-height: 300px;overflow: auto;">
      <div *ngFor="let comment of comments; let i = index">
        <div class="comment-row">
          {{ formatComment(comment) }}
        </div>
        <br />
      </div>
      <div
        *ngIf="!comments || comments.length == 0"
        style="font-style: italic;
  color: grey;"
      >
        <div class="comment-row">
          No comments yet
        </div>
        <br />
      </div>
    </div>

    <div
      style="text-align: left; font-size: smaller;"
      *ngIf="!showNewCommentBox && isResearchCountryManager"
      (click)="showNewComment()"
    >
      <label
        style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
      >
        Add new comment
      </label>
    </div>
    <div *ngIf="showNewCommentBox">
      <div style="padding-bottom: 10px;">
        <textarea
          [(ngModel)]="newComment"
          style="width: 100%; height: 100px;"
        ></textarea>
      </div>
      <div>
        <button
          class="btn btn-primary"
          (click)="addNewComment()"
          [disabled]="!newComment || newComment.length < 10"
        >
          Add
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="padding: 0; padding-top: 10px; border: 0;">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="cancelSubmission()"
      *ngIf="isResearcher || isResearchLead"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      (click)="cancelSubmission()"
      *ngIf="isResearchCountryManager"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="saveComments()"
      *ngIf="isResearchCountryManager"
    >
      Save Comments
    </button>
    <button
      type="button"
      class="btn btn-primary reject-button2"
      (click)="rejectAndSaveComments()"
      *ngIf="isResearchCountryManager && rejectionMode"
      [disabled]="!comments || comments.length == 0"
    >
      Confirm Rejection
    </button>
  </div>
</ng-template>
<ng-template #confirmationDialog>
  <div
    class="modal-header"
    style="padding: 0; border-bottom: 0; padding-bottom: 30px;"
  >
    <h4 class="modal-title" style="font-weight: 600;">
      Confirmation
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="cancelSubmission()"
    ></button>
  </div>
  <div mat-dialog-content>
    <p>
      {{ getConfirmationDialogMessage() }}
    </p>
  </div>
  <div class="modal-footer" style="padding: 0; padding-top: 10px; border: 0;">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="cancelSubmission()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="submitOrApprove()"
      [disabled]="getConfigStatus() == 'APPROVED'"
    >
      Confirm
    </button>
  </div>
</ng-template>
