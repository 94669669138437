import { Component, OnInit } from "@angular/core";
import { Industry, Tenant } from "../../models/tenant.model";
import { MatDialog } from "@angular/material/dialog";
import { EditTenantsComponent } from "../master-tenants/edit-tenants/edit-tenants.component";
import { MasterTenantsService } from "../../services/master-tenants.service";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { I18nService } from "../../services";

@Component({
  selector: "app-master-tenants",
  templateUrl: "./master-tenants.component.html",
  styleUrls: ["./master-tenants.component.scss"]
})
export class MasterTenantsComponent implements OnInit {
  tenants: Tenant[] = [];
  filterName = null;
  filterIndustry: number;
  filterCountry: string;
  filterStatus: boolean;
  filterDate: number;
  filterContact: boolean;
  private filterSubject: Subject<string> = new Subject<string>();

  industries: Industry[] = [];
  countries = [];

  page = 0;
  perPage = 100;
  total = 0;

  constructor(
    private dialog: MatDialog,
    private masterTenantsService: MasterTenantsService,
    private snackBar: MatSnackBar,
    private i18: I18nService
  ) {}

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.onFilterChange();
    });
    this.loadTenants();
    this.getIndustries();
    this.getCountries();
  }

  loadTenants(): void {
    this.masterTenantsService
      .getMasterTenants(
        this.page - 1,
        this.perPage,
        this.filterName ? this.filterName : null,
        this.filterIndustry,
        this.filterCountry,
        this.filterDate,
        this.filterStatus,
        this.filterContact
      )
      .then((data: any) => {
        this.tenants = data.content;
        this.total = data.totalElements;
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  }

  getIndustries() {
    this.masterTenantsService.getIndustries().then((resp: Industry[]) => {
      this.industries = resp;
    });
  }

  getCountries() {
    this.masterTenantsService.getCountries().then((resp: any) => {
      this.countries = resp;
    });
  }

  editTenant(tenant) {
    const dialogRef = this.dialog.open(EditTenantsComponent, {
      width: "800px",
      data: {
        tenant: tenant,
        create: false,
        industries: this.industries,
        countries: this.countries
      }
    });

    dialogRef.afterClosed().subscribe((updatedTenant: Tenant) => {
      if (updatedTenant) {
        updatedTenant.name = updatedTenant.name.trim();
        this.masterTenantsService
          .updateTenant(updatedTenant)
          .then((response: any) => {
            if (response?.error) {
              this.showMessage("master-tenant.errorName");
            } else {
              this.showMessage("master-tenant.updateMessage");
              this.loadTenants();
            }
          });
      }
    });
  }

  openAddDialog() {
    let newTenant = new Tenant();
    const dialogRef = this.dialog.open(EditTenantsComponent, {
      width: "800px",
      data: {
        tenant: newTenant,
        create: true,
        industries: this.industries,
        countries: this.countries
      }
    });

    dialogRef.afterClosed().subscribe((newTenant: Tenant) => {
      if (newTenant) {
        newTenant.name = newTenant.name.trim();
        this.masterTenantsService
          .createTenant(newTenant)
          .then((response: any) => {
            if (response?.error) {
              this.showMessage("master-tenant.errorName");
            } else {
              this.showMessage("master-tenant.createMessage");
              this.loadTenants();
            }
          });
      }
    });
  }
  onFilterChange(): void {
    this.loadTenants();
  }

  onInputChange() {
    this.filterSubject.next();
  }

  setUrl(url) {
    if (!/^https?:\/\//i.test(url)) {
      url = "https://" + url;
    }
    return url;
  }

  showMessage(message) {
    this.snackBar.open(this.i18.get(message), "Close", {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ["custom-snackbar"]
    });
  }

  onPageChange(event) {
    this.page = event;
    this.loadTenants();
  }

  validateContact(tenant) {
    return (
      (tenant.contactName !== null && tenant.contactName !== "") ||
      (tenant.contactEmail !== null && tenant.contactEmail !== "") ||
      (tenant.contactPhone !== null && tenant.contactPhone !== "")
    );
  }
}
